<template>
<!-- SECTION Users-->
  <div id="usersSection" class="col-12 justify-content-center pe-3"
  :class="(visibility.sideMenu)?'section-container-sm' :'section-container-lg'">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">
  
    <!-- HEADER Users -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100">
        
        <div class="col-lg-6 col-md-12 col-12 justify-content-center" style="display: inherit">
          <!--<ion-icon v-if="session.user.bookmark==='users'" name="bookmark" class="bookmark-button"></ion-icon>
          <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->

          <h4 class="tx-left tx-clomos-white mb-2 ps-1 pt-2">
            <ion-icon name="people-outline" class="tx-27 me-2 align-bottom"></ion-icon>
            {{dictionary["Usuarios"][session.user.language]}}
          </h4>
        </div>

        <div class="col-lg-3 col-md-7 col-12 p-2 pb-1 justify-content-center">	
          <select
          :value="currentCompany.nifCif"
          class="form-control h-100 text-nowrap bg-white" 
          style="border: solid 2px white ; color: black;"
          @change="onCurrentCompanyChanged">
            <option v-if="availableCompanies[0]!=undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
            <option v-for="company in availableCompanies" :key="company.nifCif"
            :v-if="company.nifCif != session.user.nifCif
            && company.relation != undefined
            && company.relation.referenceCompany === this.session.user.nifCif"
            :value="company.nifCif">
              {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
            </option>
          </select>
        </div>

        <div class="col-lg-3 col-md-5 col-12 p-2 pb-1">	
          <button id="btnDisplayNewUserModal"
          class="btn btn-clomos bg-white rounded p-1 inner-border fw-normal text-nowrap"
          style="width: 100%; height: 100%; vertical-align: middle; border: solid 2px white"
          @click="toggleNewUserModal">
            <ion-icon name="add-circle-outline" class="tx-clomos-dark me-2 tx-20" style="vertical-align: middle; margin-top: -0.25rem;"></ion-icon>
            <span class="ms-auto tx-clomos-dark">
              {{dictionary["Añadir usuario"][session.user.language]}}
            </span>
          </button>
        </div>
      </div>

      <div class="m-0 p-3 overflow-auto">
        <table id="usersDataTable" class="display w-100 border-1 p-0 m-0 rounded-3">
          <thead class="bg-clomos-light">
            <tr>
              <th v-for="column in usersColumns" :key="column.data"
              :width="column.width" class="tx-black fw-medium text-nowrap">
                {{dictionary[column.text][session.user.language]}}
              </th>
            </tr>
          </thead>
        </table>
      </div>

    </div>
  </div>

<!-- MODAL New User -->
  <NewUserModal
  v-if="display.newUserModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :availableCompanies="availableCompanies"
  v-on:toggleNewUserModal="toggleNewUserModal">
  </NewUserModal>

<!-- MODAL Edit User -->
  <EditUserModal
  v-if="display.editUserModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :userId="targetUserId"
  v-on:toggleEditUserModal="toggleEditUserModal">
  </EditUserModal>

  <!-- MODAL Delete Text Alert -->
  <TextAlertModal
  v-if="display.deleteUserModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :alertText="dictionary['Usuario a eliminar'][session.user.language] + ': ' + targetUserId"
  v-on:acceptAlert="onDeleteUser"
  v-on:dismissAlert="toggleDeleteUserModal">
  </TextAlertModal>

</template>

<script>
import $ from 'jquery';
import NewUserModal from '@/components/users/NewUserModal'
import EditUserModal from '@/components/users/EditUserModal'
import TextAlertModal from '@/components/other/TextAlertModal'
// Clomos
import api from '@/services/api';

export default {
  name: "UsersComponent",
  components: { NewUserModal, EditUserModal, TextAlertModal },
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    dataTableOptions: {
      type: Object,
      requiered: true,
    }
  },
  watch: { 
    currentCompany: async function() {
      await this.getUserList()
      this.displayUsersDataTable();
    }
  },
  data() {
    return {
      usersColumns: [
        {text:"Identificador", data: "userId", width: "10%", className:"tx-left",
        render: (data) => {
            return "<span data-id="+data+" class='btnTargetUser "+/*"btn "+*/"tx-bold tx-clomos-midtone text-nowrap'>"+data+"</span>"
          }
        },
        {text:"Nombre", data: "userName", width: "10%", className:"tx-left"},
        {text:"Apellidos", data: "userLastName", width: "15%", className:"tx-left"},
        {text:"Empresa", data: "userCompany", width: "15%", className:"tx-left",
          render: (data) => {
            let companyName = data;
            if (this.availableCompanies.length > 0) {
              for (let company in this.availableCompanies) 
                if (this.availableCompanies[company].nifCif === data ) 
                  companyName = this.availableCompanies[company].name;
            }
            return companyName;
          }
        },
        {text:"Rol", data: "userRol", width: "15%", className:"tx-left",
          render: (data) => {
            let rolLabel = "";
            switch (data) { 
              case "255": 
                rolLabel = this.dictionary['Administrador general'][this.session.user.language]
                break;
              case "128": 
                rolLabel = this.dictionary['Administrador de la empresa'][this.session.user.language]
                break;
              case "32": 
                rolLabel = this.dictionary['Autorizado'][this.session.user.language]+"/"+this.dictionary['Editor'][this.session.user.language]
                break;
              case "2": 
                rolLabel = this.dictionary['Empleado'][this.session.user.language]+"/"+this.dictionary['Visor'][this.session.user.language]
                break;
              case "1": 
                rolLabel = this.dictionary['Agente'][this.session.user.language]+"/"+this.dictionary['Representante'][this.session.user.language]
                break;
            }
            return rolLabel;
          }
        },
        {text:"Teléfono", data: "userTelephone", width: "10%", className:"tx-left"},
        {text:"Dirección", data: "userEmail", width: "15%", className:"tx-left"},
        {text:"Editar", data: "userId", width: "5%",
          render: (data) => {
            if (this.session.user.id===data)
              return "<ion-icon name='create-outline' data-id="+data+" class='btnEditMyProfile btn tx-24 tx-primary  p-0 m-0'></ion-icon>"
            else if (Number(this.session.user.rol)>=128)
              return "<ion-icon name='create-outline' data-id="+data+" class='btnEditUser btn tx-24 tx-primary  p-0 m-0'></ion-icon>"
            else 
              return "<ion-icon name='create-outline' data-id="+data+" class='tx-24 tx-gray-400'></ion-icon>"
          }
        },
        {text:"Eliminar", data: "userId", width: "5%",
          render: (data) => {
            if (this.session.user.id!=data && Number(this.session.user.rol)>=128 )
              return "<ion-icon name='trash-outline' data-id="+data+" class='btnDeleteUser btn tx-24 tx-primary  p-0 m-0'></ion-icon>"
            else
              return "<ion-icon name='trash-outline' data-id="+data+" class='tx-24 tx-gray-400'></ion-icon>"
          }
        }
      ],
      targetUserId: this.$route.params.targetEntity,
      userList: [],
      display: {
        newUserModal: false,
        editUserModal: false,
        deleteUserModal: false
      }
    }
  },
  emits: ["setCurrentCompany", "getAvailableCompanies"],
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$emit('setCurrentCompany',value);
    },
    displayUsersDataTable() {
      $("#usersDataTable").DataTable().clear()
      for (let i in this.userList) {
        $("#usersDataTable").DataTable().rows.add([{
          userId: this.userList[i].id, 
          userName: this.userList[i].name, 
          userLastName: this.userList[i].lastName, 
          userEmail: this.userList[i].email, 
          userRol: this.userList[i].rol, 
          userTelephone: this.userList[i].telephone, 
          userCompany: this.userList[i].nifCif,
        }]);
      }
      $("#usersDataTable").DataTable().draw();
      this.setButtonFunctions();
     if($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
    },
    async toggleNewUserModal() {
      if (this.display.newUserModal) {
        await this.getUserList();
        this.displayUsersDataTable();
      }
      this.display.newUserModal = !this.display.newUserModal;
    },
    async toggleEditUserModal() {
      if (this.display.editUserModal) {
        await this.getUserList();
        this.displayUsersDataTable();
      }
      this.display.editUserModal = !this.display.editUserModal;
    },
    async toggleDeleteUserModal() {
      if (this.display.deleteUserModal) {
        await this.getUserList();
        this.displayUsersDataTable();
      }
      this.display.deleteUserModal = !this.display.deleteUserModal;
    },
    // BUTTONS
    setButtonFunctions() {
      $(".btnTargetUser").get().forEach(btn => {
        btn.addEventListener('click', this.onTargetUser);
      });
      $(".btnEditMyProfile").get().forEach(btn => {
        btn.addEventListener('click', this.onTargetMyUser);
      });
      $(".btnEditUser").get().forEach(btn => {
        btn.addEventListener('click', this.onEditUser);
      });
      $(".btnDeleteUser").get().forEach(btn => {
        btn.addEventListener('click', this.onDeleteUser);
      });
    },
    // GET
    async getUserList(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getUserList(request);
      this.userList = api.parseUsersFromResponse(response);
      if (callback && typeof callback === "function") callback(this.userList);
    },
    // TARGET 
    onTargetUser(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetUserId = event.target.getAttribute('data-id');
      this.$router.replace({params: {targetEntity: this.targetUserId}});
    },
    onTargetMyUser() {
      this.$router.replace({params: {currentDisplay: 'profile'}});
    },
    // SET
    onEditUser(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetUserId = event.target.getAttribute('data-id');
      this.$router.replace({params: {targetEntity: this.targetUserId}});
      this.toggleEditUserModal();
    },
    // DELETE
    async onDeleteUser(event) {
      if (!this.display.deleteUserModal) {
        console.log('DELETE WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetUserId = event.target.getAttribute('data-id');
        this.$router.replace({params: {targetEntity: this.targetUserId}});
      }
      else {
        let response = await api.deleteUsers([this.targetUserId]);
        console.log(response);
      }
      this.toggleDeleteUserModal();
    }
  },
  async created() {
    await this.getUserList();
    this.$emit('getAvailableCompanies',(this.displayUsersDataTable));
  },
  mounted() {
    $("#usersDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn ms-auto' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.usersColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    );
  }
}
</script>

<style>
/*.btnTargetUser:hover,*/ .btnEditUser:hover, .btnDeleteUser:hover {
  color: var(--main-dark);
}
</style>