<template>
<!-- SECTION Expeditions-->
  <div id="expeditionsSection" class="col-12 justify-content-center pe-3"
  :class="(visibility.sideMenu)?'section-container-sm' :'section-container-lg'">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">
  
<!-- HEADER Expeditions -->

      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100" >

        <div class="col-lg-6 col-md-12 col-12 justify-content-center" style="display: inherit">
          <!--<ion-icon v-if="session.user.bookmark==='expeditions'" name="bookmark" class="bookmark-button"></ion-icon>
          <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
          <h4 class="tx-left tx-clomos-white text-nowrap mb-2 ps-1 pt-2" style="height:40px;">

            <ion-icon name="arrow-redo-outline" class="tx-27 me-2 align-bottom"></ion-icon>
            {{dictionary["Expedición de productos"][session.user.language]}}
          </h4>
        </div>

<!-- HEADER Display Type -->
        <div class="d-flex justify-content-between col-lg-3 col-md-6 col-12 p-0 ps-1 pe-1 m-0">	
          <div class="w-third p-1 pt-2">	
            <button @click="displayType='all'"
            :style="(displayType==='all')?'border-color: coral' :'border-color: white'"
            class="col-xl-4 col-md-4 col-sm-12 header-tab-button btn bg-clomos rounded p-0 text-nowrap">
              <ion-icon name="ellipsis-vertical-circle-outline" 
              :style="(displayType==='all')?'color: coral':'color: white'"
              class="tx-23" style="vertical-align: middle; margin-top: -0.25rem;"></ion-icon>
            </button>
          </div>
          <div class="w-third p-1 pt-2">	
            <button @click="displayType='toDo'"
            :style="(displayType==='toDo')?'border-color: coral' :'border-color: white'"
            class="col-xl-4 col-md-4 col-sm-12 header-tab-button btn bg-clomos rounded p-0 text-nowrap">
              <ion-icon name="time-outline" 
              :style="(displayType==='toDo')?'color: coral':'color: white'"
              class="tx-23" style="vertical-align: middle; margin-top: -0.25rem;"></ion-icon>
            </button>
          </div>
          <div class="w-third p-1 pt-2">	
            <button @click="displayType='done'"
            :style="(displayType==='done')?'border-color: coral' :'border-color: white'"
            class="col-xl-4 col-md-4 col-sm-12 header-tab-button btn bg-clomos rounded p-0 text-nowrap">
              <ion-icon name="checkmark-circle-outline" 
              :style="(displayType==='done')?'color: coral':'color: white'"
              class="tx-23" style="vertical-align: middle; margin-top: -0.25rem;"></ion-icon>
            </button>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-12 p-2 pb-1">	
          <button id="btnDisplayNewExpeditionModal"
          class="btn btn-clomos bg-white rounded p-1 inner-border fw-normal text-nowrap"
          style="width: 100%; height:100%; vertical-align: middle; border: solid 2px white"
          @click="toggleNewExpeditionModal">
              <ion-icon name="add-circle-outline" class="tx-clomos-dark me-2 tx-20" style="vertical-align: middle; margin-top: -0.25rem;"></ion-icon>
              <span class="ms-auto tx-clomos-dark">{{dictionary["Añadir expedición"][session.user.language]}}</span>
          </button>
        </div>
      </div>

      <div class="m-0 p-3 overflow-auto">
        <table id="expeditionsDataTable" class="display w-100 border-1 p-0 m-0 rounded-3">
          <thead class="bg-clomos-light">
            <tr>
              <th v-for="column in expeditionsColumns" :key="column.data"
              :width="column.width" class="tx-black fw-medium text-nowrap">
                {{dictionary[column.text][session.user.language]}}
              </th>
            </tr>
          </thead>
        </table>
      </div>

    </div>
  </div>

<!-- MODAL New Expedition -->
  <NewExpeditionModal
  v-if="display.newExpeditionModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :availableCompanies="availableCompanies"
  v-on:toggleNewExpeditionModal="toggleNewExpeditionModal">
  </NewExpeditionModal> 

<!-- MODAL Prepare Expedition -->
  <PrepareExpeditionModal
  v-if="display.prepareExpeditionModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :availableCompanies="availableCompanies"
  :targetExpeditionId="targetExpeditionId"
  v-on:togglePrepareExpeditionModal="togglePrepareExpeditionModal">
  </PrepareExpeditionModal> 

<!-- MODAL Delete Text Alert -->
  <TextAlertModal
  v-if="display.endExpeditionModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :alertText="dictionary['Expedir'][session.user.language] + ': ' + targetExpeditionId"
  v-on:acceptAlert="onDeleteExpedition"
  v-on:dismissAlert="toggleEndExpeditionModal">
  </TextAlertModal>

<!-- MODAL Delete Text Alert -->
  <TextAlertModal
  v-if="display.deleteExpeditionModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :alertText="dictionary['Expedición a eliminar'][session.user.language] + ': ' + targetExpeditionId"
  v-on:acceptAlert="onDeleteExpedition"
  v-on:dismissAlert="toggleDeleteExpeditionModal">
  </TextAlertModal>

</template>

<script>
import $ from 'jquery';
import NewExpeditionModal from '@/components/expeditions/NewExpeditionModal';
import PrepareExpeditionModal from '@/components/expeditions/PrepareExpeditionModal';
import TextAlertModal from '@/components/other/TextAlertModal';
// Clomos
import api from '@/services/api';

export default {
  name: "ExpeditionsComponent",
  components: { PrepareExpeditionModal, NewExpeditionModal, TextAlertModal  },
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    dataTableOptions: {
      type: Object,
      requiered: true,
    }
  },
  watch: { 
    currentCompany: async function() {
      await this.getExpeditionList();
      this.displayExpeditionsDataTable();
    }
  },
  data() {
    return {
      expeditionsColumns: [
        {text:"Albarán", data: "expeditionId", width: "10%",
          render: (data) => {
            return "<ion-icon name='list-circle-outline' data-id="+data+" class='btnDetailExpedition btn tx-24 tx-primary  p-0 m-0'></ion-icon>";
          }
        },
        {text:"Identificador", data: "expeditionId", width: "15%"},
        {text:"Empresa", data: "expeditionCompany", width: "20%",
          render: (data) => {
            let companyName = data;
            if (this.availableCompanies.length > 0) {
              for (let company in this.availableCompanies) 
                if (this.availableCompanies[company].nifCif === data ) 
                  companyName = this.availableCompanies[company].name;
            } 
            return companyName;
          }
        },
        {text:"Tipo", data: "expeditionType", width: "5%",
          render: (data) => {
            return this.dictionary[data][this.session.user.language]
          }
        },
        {text:"Fecha de ejecución", data: "expeditionExecutionDateTime", width: "15%"},
        {text:"Fecha de creación", data: "expeditionCreationDateTime", width: "15%"},
        {text:"Descargar", data: "expeditionId", width: "5%",
          render: (data) => {
            return "<ion-icon name='download-outline' data-id="+data+" class='btnDownloadExpedition btn tx-24 tx-primary  p-0 m-0'></ion-icon>";
          }
        },
        {text:"Preparar", data: "expeditionStatus", width: "5%",
          render: (data) => {
            return (data.status==="0")
              ? "<ion-icon name='chevron-forward-circle-outline' data-id="+data.id+" class='btnPrepareExpedition btn tx-24 tx-primary p-0 m-0'></ion-icon>"
              : "<ion-icon name='checkmark-circle-outline' data-id="+data.id+" class='btnPreparedExpedition btn tx-24 tx-success p-0 m-0' disabled></ion-icon>";
          }
        },
        {text:"Expedir", data: "expeditionStatus", width: "5%",
          render: (data) => {
            return (data.status==="0")
              ? "<ion-icon name='chevron-forward-circle-outline' data-id="+data.id+" class='btn tx-24 tx-clomos-midtone opacity-50 p-0 m-0' disabled></ion-icon>"
              : (data.status==="1")
                ? "<ion-icon name='chevron-forward-circle-outline' data-id="+data.id+" class='btnEndExpedition btn tx-24 tx-primary p-0 m-0'></ion-icon>"
                : "<ion-icon name='checkmark-circle-outline' data-id="+data.id+" class='btnExecutedExpedition btn tx-24 tx-success p-0 m-0' disabled></ion-icon>";
          }
        },
        {text:"Eliminar", data: "expeditionStatus", width: "5%",
          render: (data) => {
            return (data.status==="0")
              ? "<ion-icon name='trash-outline' data-id="+data.id+" class='btnDeleteExpedition btn tx-24 tx-primary p-0 m-0'></ion-icon>"
              : "<ion-icon name='trash-outline' data-id="+data.id+" class='btn tx-24 tx-clomos-midtone opacity-50 p-0 m-0' disabled></ion-icon>";
          }
        }
      ],
      displayType : 'all',
      targetExpeditionId: "",
      expeditionList: [],
      bulgeInput: {
        volume: {
          height: 0,
          width: 0,
          depth: 0
        },
        units: 0
      },
      bulgeList: [],
      display: {
        newExpeditionModal: false,
        prepareExpeditionModal: false,
        endExpeditionModal: false,
        deleteExpeditionModal: false
      }
    }
  },
  emits: ["setCurrentCompany", "getAvailableCompanies"],
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({params: {currentCompanyNifCif: value}})
      this.$emit('setCurrentCompany',value);
    },
    displayExpeditionsDataTable() {
      $("#expeditionsDataTable").DataTable().clear()
      for (let i in this.expeditionList) {
        $("#expeditionsDataTable").DataTable().rows.add([{
          expeditionId: this.expeditionList[i].id, 
          expeditionCompany: this.expeditionList[i].company, 
          expeditionType: this.expeditionList[i].type,
          expeditionStatus: {id: this.expeditionList[i].id, status: this.expeditionList[i].status}, 
          expeditionPosition: this.expeditionList[i].position, 
          expeditionExecutionDateTime: this.expeditionList[i].executionDateTime, 
          expeditionCreationDateTime: this.expeditionList[i].creationDateTime, 
        }]);
      }
      $("#expeditionsDataTable").DataTable().draw();
      this.setButtonFunctions();
     if($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
    },
    async toggleNewExpeditionModal() {
      if (this.display.newExpeditionModal) {
        await this.getExpeditionList();
        this.displayExpeditionsDataTable();
      }
      this.display.newExpeditionModal = !this.display.newExpeditionModal;
    },
    async togglePrepareExpeditionModal() {
      if (this.display.prepareExpeditionModal) {
        await this.getExpeditionList();
        this.displayExpeditionsDataTable();
      }
      this.display.prepareExpeditionModal = !this.display.prepareExpeditionModal;
    },
    async toggleEndExpeditionModal() {
      if (this.display.endExpeditionModal) {
        await this.getExpeditionList();
        this.displayExpeditionsDataTable();
      }
      this.display.endExpeditionModal = !this.display.endExpeditionModal;
    },
    async toggleDeleteExpeditionModal() {
      if (this.display.deleteExpeditionModal) {
        await this.getExpeditionList();
        this.displayExpeditionsDataTable();
      }
      this.display.deleteExpeditionModal = !this.display.deleteExpeditionModal;
    },
    // BUTTONS
    setButtonFunctions() {
      $(".btnTargetExpedition").get().forEach(btn => {
        btn.addEventListener('click', this.onTargetExpedition);
      });
      $(".btnDetailExpedition").get().forEach(btn => {
        btn.addEventListener('click', this.onDetailExpedition)
      });
      $(".btnPrepareExpedition").get().forEach(btn => {
        btn.addEventListener('click', this.onPrepareExpedition);
      }); 
      $(".btnEndExpedition").get().forEach(btn => {
        btn.addEventListener('click', this.onEndExpedition);
      });
      $(".btnDeleteExpedition").get().forEach(btn => {
        btn.addEventListener('click', this.onDeleteExpedition);
      });
    },
    // GET
    async getExpeditionList(callback) {
      let request = {
        userId: this.session.user.id
      };
      let response = await api.getExpeditionList(request);
      console.log(response)
      // this.expeditionList = api.parseExpeditionsFromResponse(response);
      this.expeditionList = [
        {
          id: "001",
          company: "DSI",
          type: "retirada",
          status: "0",
          creationDateTime: "1/8/2023",
          executionDateTime: "2/8/2023",
          doneDateTime: "3/8/2023",
          products: [{idDcl: "002", talla:"M", unidades: "20"}, {idDcl: "001", talla:"XL", unidades: "20"}, {idDcl: "003", talla:"S", unidades: "20"},],
        },
        {
          id: "002",
          company: "DSI",
          type: "pedido",
          status: "1",
          creationDateTime: "1/8/2023",
          executionDateTime: "2/8/2023",
          doneDateTime: "3/8/2023",
          products: [{idDcl: "002", talla:"M", unidades: "20"}, {idDcl: "001", talla:"XL", unidades: "20"}, {idDcl: "003", talla:"S", unidades: "20"},],
        },
        {
          id: "003",
          company: "DSI",
          type: "pedido",
          status: "2",
          creationDateTime: "1/8/2023",
          executionDateTime: "2/8/2023",
          doneDateTime: "3/8/2023",
          products: [{idDcl: "002", talla:"M", unidades: "20"}, {idDcl: "001", talla:"XL", unidades: "20"}, {idDcl: "003", talla:"S", unidades: "20"},],
        },
      ]
      if (callback && typeof callback === "function") callback(this.expeditionList);
    },
    // TARGET 
    onTargetExpedition(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetExpeditionId = event.target.getAttribute('data-id');
    },
    // PREPARE
    onPrepareExpedition(event) {
      console.log('PREPARE WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetExpeditionId = event.target.getAttribute('data-id')
      this.togglePrepareExpeditionModal();
    },
    // DETAILS
    onDetailExpedition(event) {
      console.log('DETAIL WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetExpeditionId = event.target.getAttribute('data-id')
      var tr = $(event.target).closest('tr');
      var row = $("#expeditionsDataTable").DataTable().row(tr);
      if (row.child.isShown()) {
        event.target.name = "list-circle-outline";
        row.child.hide();
        tr.removeClass('shown');
      } 
      else {
        event.target.name = "chevron-up-circle-outline";
        let content = '' +
          '<table id="detailTable'+this.targetExpeditionId+'" cellspacing="0" border="0" style="width:100%; margin: 0;">' +
            '<tr>' +
              '<td class="tx-bold">Producto</td>' +
              '<td class="tx-bold">Cantidad</td>' +
              '<td class="tx-bold">Talla</td>' +
              '<td class="tx-bold">Color</td>' +
              '<td class="tx-bold">Caducidad</td>' +
            '</tr>' +
            '<tr>' +
              '<td>' + this.targetExpeditionId +'-AL' + '</td>' +
              '<td>' + '102' + '</td>' +
              '<td>' + 'M' + '</td>' +
              '<td>' + 'Rojo' + '</td>' +
              '<td>' + '-' + '</td>' +
            '</tr>' +
            '<tr>' +
              '<td>' + this.targetExpeditionId +'-AL' + '</td>' +
              '<td>' + '41' + '</td>' +
              '<td>' + 'XL' + '</td>' +
              '<td>' + 'Verde' + '</td>' +
              '<td>' + '-' + '</td>' +
            '</tr>' +
          '</table>';
        row.child(content).show();
        document.getElementById('detailTable'+this.targetExpeditionId).parentNode.style.padding = 0;
        tr.addClass('shown');
      }
    },
    // SET
    onEndExpedition(event) {
      if (!this.display.deleteExpeditionModal) {
        console.log('END WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetExpeditionId = event.target.getAttribute('data-id');
      }
      else {
        // let response = await api.deleteOrder(this.targetExpeditionId);
        // console.log(response);
      }
      this.toggleEndExpeditionModal();
    },
    // DELETE
    async onDeleteExpedition(event) {
      if (!this.display.deleteExpeditionModal) {
        console.log('DELETE WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetExpeditionId = event.target.getAttribute('data-id');
      }
      else {
        let response = await api.deleteOrder(this.targetExpeditionId);
        console.log(response);
      }
      this.toggleDeleteExpeditionModal();
    }
  },
  async created() {
    await this.getExpeditionList()
    this.$emit('getAvailableCompanies',(this.displayExpeditionsDataTable));
  },
  mounted() {
    $("#expeditionsDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn ms-auto' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.expeditionsColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    );
  }
}
</script>
<style>
#prepareExpeditionModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

.btnDetailExpedition:hover, .btnPrepareExpedition:hover, .btnDownloadExpedition:hover, .btnEndExpedition:hover, .btnDeleteExpedition:hover {
  color: var(--main-dark);
}
.btnPreparedExpedition:hover, .btnExecutedExpedition:hover {
  color: var(--main-dark-50);
}
</style>