<template>
<!-- MODAL New Bundle -->
  <div id="newBundleModal"
  class="col-12 justify-content-center pe-6"
  style="padding: 15vh 20%">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">
      
<!-- HEAD New Bundle-->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100">
        <div class="col-10">
          <h4 class="tx-left tx-clomos-white mb-2 ps-1 pt-2">
            <ion-icon name="grid-outline" class="tx-27 me-2 align-bottom"></ion-icon>
            {{dictionary['Nuevo conjunto'][session.user.language]}}
          </h4>
        </div>

        <div class="col-2 m-0 p-0" style="display: flex; justify-content: end;"
        @click="$emit('toggleNewBundleModal')">	
          <ion-icon name="close" class="ms-auto tx-46 tx-white align-bottom"></ion-icon>
        </div>
      </div>

<!-- CONTENT New Bundle -->

      <div class="col-12 p-0 w-100">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="card-body">

            <form class="form-material border-1 p-3 pt-0 overflow-hidden">
              <div class="container-fluid p-3">
                
                <div class="row">
                  
<!-- NOMBRE -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Nombre'][session.user.language]}} *
                    </label>
                    <div class="col-md-12">
                      <input v-model="newBundleData.label" id="inputNewBundleLabel" type="text" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

<!-- TIPO -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Tipo'][session.user.language]}} *
                    </label>
                    <div class="col-md-12">
                      <select v-model="newBundleData.typeId" 
                      id="selectNewBundleType"
                      class="form-control form-control-line" style="color:black;">
                        <option v-for="type in bundleTypeList" :key="type.id" :value="type.id">{{type.label}}</option>
                      </select>
                    </div>
                  </div>

                </div>
      
                <div class="row">
                  
<!-- MÁQUINA -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Máquina'][session.user.language]}} *
                    </label>
                    <div class="col-md-12">
                      <select v-model="newBundleData.machineId" 
                      id="selectNewBundleType"
                      class="form-control form-control-line" style="color:black;">
                        <option v-for="machine in machineList" :key="machine.id" :value="machine.id">{{machine.label}}</option>
                      </select>
                    </div>
                  </div>

                </div>
              
                <div class="row">
                
                  <div class="form-group col-md-6 col-12 p-1">
                  </div>

                  <div class="form-group col-md-6 col-12 p-1">
                    <div class="col-12 mt-4">
<!-- SUBMIT -->
                      <button type="button" id="btnSubmitCreateNewBundle" class="btn btn-clomos w-100"
                      @click="onCreateNewBundle">
                        {{dictionary['Crear nuevo conjunto'][session.user.language]}}
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </form>
              
          </div>
        </div>
      </div>
      
    </div>
  </div>

</template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {
  name: "NewBundleModal",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
  },
  data() {
    return {
      newBundleData: {
        nifCif: this.currentCompany.nifCif,
        label: "",
        typeId: "",
        machineId: "",
      },
      bundleTypeList: [],
      machineList: []
    }
  },
  methods: {
    async onCreateNewBundle() {
      let valid = true;
      if (this.newBundleData.label === "") {
        $("#inputNewBundleLabel").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.newBundleData.label === "") {
        $("#selectNewBundleType").get()[0].classList.add("missing")
        valid = false;
      }
      if (!valid) {
        setTimeout(() => {
          $("#inputNewBundleLabel").get()[0].classList.remove("missing")
          $("#selectNewBundleType").get()[0].classList.remove("missing")
        }, 3000)
        return;
      }
      let response = await api.postNewBundle(this.newBundleData);
      console.log(response);
      if (response["status-code"] === "201") {
        this.$emit('toggleNewBundleModal');
        window.alert("El conjunto ha sido creado satisfactoriamente.");
      }
      else {
        window.alert("Error al crear el conjunto.");
      }
    },
    async getBundleTypeList() {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getBundleTypeList(request);
      for (let i in response.tiposDcl) [
        this.bundleTypeList[i] = {
          id: response.tiposDcl[i].idTipoDcl,
          label: response.tiposDcl[i].etiqueta,
        }
      ]
      console.log(response);
    },
    async getMachineListByCompany() {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getMachineListByCompany(request);
      for (let i in response.maquinas) [
        this.machineList[i] = {
          id: response.maquinas[i].idDcl,
          label: response.maquinas[i].etiqueta,
        }
      ]
      console.log(response);
    }
  },
  async created() {
    await this.getBundleTypeList();
    await this.getMachineListByCompany();
  }
}
</script>

<style>

#newBundleModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>