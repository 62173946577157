<template>
<!-- MODAL Edit Stock -->
  <div id="regularizeStockModal"
  class="col-12 justify-content-center pe-6"
  style="padding: 15vh 20%">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">

<!-- HEAD Edit Stock -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100">
        <div class="col-10">
          <h4 class="tx-left tx-clomos-white mb-2 ps-1 pt-2">
            <ion-icon name="cube-outline" class="tx-27 me-2 align-bottom"></ion-icon>
            {{dictionary['Editar stock'][session.user.language]}}
          </h4>
        </div>

        <div class="col-2 m-0 p-0" style="display: flex; justify-content: end;"
        @click="$emit('toggleStockModal')">	
          <ion-icon name="close" class="ms-auto tx-46 tx-white align-bottom"></ion-icon>
        </div>
      </div>

      <div class="col-12 p-0 w-100">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="card-body">

<!-- CONTENT Stock -->
            <form class="form-material border-1 p-3" style="overflow: scroll; overflow-x: auto; overflow-y: hidden;">
                
                <div class="d-flex mb-3 w-100">
                  
<!-- NOMBRE -->
                  <div class="col-md-6 col-12 m-0 p-0">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Referencia'][session.user.language]}}:
                    </label>
                    <div class="col-md-12">
                      <p id="inputRegularizeStockName" class="m-0 p-0 tx-13"> {{stockData.reference}} </p>
                      <div class="col-12 p-0">
                        <hr class="tx-clomos-dark m-0">
                      </div>
                    </div>
                  </div>
                  
                  <div class="col-md-6 col-12 m-0 p-0">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Nombre'][session.user.language]}}:
                    </label>
                    <div class="col-md-12">
                      <p id="inputRegularizeStockName" class="m-0 p-0 tx-13"> {{stockData.name}} </p>
                      <div class="col-12 p-0">
                        <hr class="tx-clomos-dark m-0">
                      </div>
                    </div>
                  </div>
                  
                </div>


<!-- LISTA Stocks -->
                <div class="col-12 m-0 mb-3 p-0 border-1 rounded-3"
                style="overflow: scroll; overflow-y: hidden;">
                  
                  <table id="stocksTable" class="display col-12 p-0 m-0">
                    <thead class="bg-clomos-portada border-bottom">
                      <tr>
                        <th class="tx-black fw-medium text-nowrap w-20">
                          {{dictionary['Talla'][session.user.language]}}
                        </th>
                        <th class="tx-black fw-medium text-nowrap w-20">
                          {{dictionary['Color'][session.user.language]}}
                        </th>
                        <th class="tx-black fw-medium text-nowrap w-20">
                          {{dictionary['Fecha de caducidad'][session.user.language]}}
                        </th>
                        <th class="tx-black fw-medium text-nowrap w-20">
                          {{dictionary['Stock actual'][session.user.language]}}
                        </th>
                      </tr>
                    </thead>
                    <tr v-for="stock in stockData.stockList" :key="stock" class="border-top">
                      <td>
                        {{stock.size}}
                      </td>
                      <td>
                        {{stock.color}}
                      </td>
                      <td>
                        {{stock.expirationDate}}
                      </td>
                      <td>
                        {{stock.amount}}
                      </td>
                    </tr>
                  </table>

                </div>

  <!-- CARD Order Pie -->
                <div id="cardOrderHistoryPieChart" class="col-12 p-0 m-0">
                  <h5 class="bg-clomos-midtone rounded-top-3 p-1 mb-0 tx-clomos-white">
                    {{ dictionary['Órdenes'][session.user.language] }}
                  </h5>
                  <div class="border-1 pt-3 pb-3 m-0 mb-3">
                    <VueEcharts :v-if="orderHistoryPieChartOptions[0]!=undefined"
                    style="height: 15rem;"
                    :option="orderHistoryPieChartOptions"
                    id="orderHistoryPieChartCanvas" class="col-12 ps-0 pe-0">
                    </VueEcharts>
                  </div>
                </div>

  <!-- CARD Order History -->
                <div id="cardOrderHistoryLineChartCanvas" class="col-lg-12 col-md-12 col-12 p-0 m-0" style="min-width: 750px">
                  <h5 class="bg-clomos-midtone rounded-top-3 p-1 mb-0 tx-clomos-white">
                    {{ dictionary['Histórico de ordenes'][session.user.language] }}
                  </h5>

                  <div class="border-1 pt-3 pb-3 m-0"
                  style="height: 30rem; overflow: scroll; overflow-y: hidden;">
                    <div class="m-0 p-0" style="width: 100%; height: 30rem;">
                      <VueEcharts :v-if="orderHistoryLineChartOptions[0]!=undefined"
                      :option="orderHistoryLineChartOptions"
                      class="col-12 ps-0 pe-0"
                      id="orderHistoryLineChartCanvas">
                      </VueEcharts>
                    </div>
                  </div>
                </div>

            </form>
              
          </div>
        </div>
      </div>
      
    </div>
  </div>

</template>

<script>
import { VueEcharts } from 'vue3-echarts';
import echartsOptionsJson from '@/assets/json/echarts_options.json';
// Clomos
import api from '@/services/api';

export default {
  name: "RegularizeStockModal",
  components: { VueEcharts },
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    productId: {
      type: String,
      requiered: true,
    },
  },
  data() {
    return {
      // ORDER HISTORY LINE CHART
      lineChartOptions: JSON.parse(JSON.stringify(echartsOptionsJson))["lineChartOptions"],
      // ORDER HISTORY PIE CHART
      pieChartOptions: JSON.parse(JSON.stringify(echartsOptionsJson))["pieChartOptions"],
      stockData: {
        nifCif: this.currentCompany.nifCif,
        id: "",
        reference: "",
        name: "",
        stockList: []
      },
      orderHistory: [],
    }
  },
  computed: {
// ORDER HISTORY CHART
    orderHistoryLineChartOptions() {
      let orderHistoryLineChartOptions;
      let now = new Date().getTime();
      let yesterday = new Date().setDate(new Date().getDate() - 1);
      
      orderHistoryLineChartOptions = JSON.parse(JSON.stringify(this.lineChartOptions));
      if (this.targetDeviceId === "") return orderHistoryLineChartOptions;
      orderHistoryLineChartOptions.axisPointer = {
        label: {
          formatter: (function(dataTarget) {
            let d = new Date(dataTarget.value);
            return String(d.getHours()).padStart(2,"0")+":"+String(d.getMinutes()).padStart(2,"0")+":"+String(d.getSeconds()).padStart(2,"0");
          })
        },
      }
      orderHistoryLineChartOptions.xAxis = {
        min: yesterday,
        max: now,
        axisLabel: {
          formatter: (function(value) {
            let d = new Date(value);
            return String(d.getHours()).padStart(2,"0")+":"+String(d.getMinutes()).padStart(2,"0")+":"+String(d.getSeconds()).padStart(2,"0");
          })
        }
      }
      orderHistoryLineChartOptions.yAxis = {
        type: "value"
      }
      let i = 0;
      for (let variable in this.orderHistory) {
        orderHistoryLineChartOptions.legend.data[i] = variable;
        orderHistoryLineChartOptions.series[i] = {
          data : this.orderHistory[variable],
          name : variable,
          type: "line",
          color : this.colors[i],
          smooth : false,
          step : true,
        }
        i+=1;
      }
      return orderHistoryLineChartOptions;
    },
// ORDER HISTORY CHART
    orderHistoryPieChartOptions() {
      let orderHistoryPieChartOptions;
      //let now = new Date().getTime();
      //let yesterday = new Date().setDate(new Date().getDate() - 1);
      
      orderHistoryPieChartOptions = JSON.parse(JSON.stringify(this.pieChartOptions));
      if (this.targetDeviceId === "") return orderHistoryPieChartOptions;
      orderHistoryPieChartOptions.series[0].data = [
        { value: 1048, name: 'Recepciones' },
        { value: 735, name: 'Expediciones' },
        { value: 19, name: 'Regularizaciones' },
      ]
      return orderHistoryPieChartOptions;
    }
  },
  emits: ["toggleStockModal"],
  methods: {
    // GET 
    async getStockData(callback) {
      this.stockData.name = "ZAPATILLAS",
      this.stockData.reference = "ZP-003",
      this.stockData.stockList = [
        { 
          color: "Verde",
          size: "L",
          expirationDate: "-",
          amount: 10,
          newAmount: 10
        },
        { 
          color: "Verde",
          size: "XL",
          expirationDate: "-",
          amount: 15,
          newAmount: 15
        },
        { 
          color: "Azul",
          size: "XXL",
          expirationDate: "-",
          amount: 20,
          newAmount: 20
        }
      ]
      let response = await api.getStockData(this.productId);
      this.stockData = api.parseStockFromResponse(response);
      if (callback && typeof callback === "function") callback(this.stockData);
    },
    async getOrderHistory(callback) {
      if (this.stockData != {}) for (let variableType in this.stockData.variables) {
        for (let variable in this.stockData.variables[variableType]) {
          if (this.stockData.variables[variableType][variable] == undefined || this.stockData.variables[variableType][variable].saveHistory!="true") continue;
          let request = {
            id: this.stockData.id,
            variableLabel: this.stockData.variables[variableType][variable].label,
            variableType: this.stockData.variables[variableType][variable].type,
            limit: 0,
            offset: 0,  
            endDateTime: api.parseDateTimeToString(new Date()),
            startDateTime: api.parseDateTimeToString(new Date().setDate(new Date().getDate() - 1)),
          };
          let response = await api.getOrderHistory(request);
          let rawHistory = api.parseOrderHistoryFromResponse(response);
          this.variableHistory[this.stockData.variables[variableType][variable].label] = [];
          for (let i in rawHistory) {
            this.variableHistory[this.stockData.variables[variableType][variable].label][i] = [
              // DATE
              this.$dayjs(new Date(rawHistory[i][0])).utc("z").local().tz(this.session.user.dateTimeZone).$d,
              // VALUE
              new Number(rawHistory[i][1]).toFixed(2)
            ];
          }
          if (callback && typeof callback === "function") callback(this.deviceList);
        }
      }
    },
    // SET
    async setStockData() {
      let response = await api.setStockData(this.stockData);
      console.log(response);
      if (response["status-code"] === "200") {
        this.$emit('toggleStockModal');
        window.alert("El stock ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar el stock.");
      }
      this.getStockData();
    },
  },
  created() {
    this.getStockData();
  }
}
</script>

<style>

#regularizeStockModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

@media (max-width: 767px) {
  #stockImgUploadFormContainer {
    flex-direction: column;
  }
}
</style>