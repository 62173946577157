<template>
<!-- SECTION Orders-->
  <div id="ordersSection" class="col-12 justify-content-center pe-3"
  :class="(visibility.sideMenu)?'section-container-sm' :'section-container-lg'">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">
  
<!-- HEADER Orders -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100" >

        <div class="col-lg-6 col-md-12 col-12 justify-content-center" style="display: inherit">
          <!--<ion-icon v-if="session.user.bookmark==='orders'" name="bookmark" class="bookmark-button"></ion-icon>
          <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
          <h4 class="tx-left tx-clomos-white text-nowrap mb-2 ps-1 pt-2" style="height:40px;">

            <ion-icon name="basket-outline" class="tx-27 me-2 align-bottom"></ion-icon>
            {{dictionary["Pedidos"][session.user.language]}}
          </h4>
        </div>

        <div class="col-lg-3 col-md-12 col-12 m-0 p-0"></div>

<!-- HEADER Display Type -->
        <div class="d-flex justify-content-between col-lg-3 col-md-6 col-12 p-0 ps-1 pe-1 m-0">	
          <div class="w-third p-1 pt-2">	
            <button @click="displayType='all'"
            :style="(displayType==='all')?'border-color: coral' :'border-color: white'"
            class="col-xl-4 col-md-4 col-sm-12 header-tab-button btn bg-clomos rounded p-0 text-nowrap">
              <ion-icon name="ellipsis-vertical-circle-outline" 
              :style="(displayType==='all')?'color: coral':'color: white'"
              class="tx-23" style="vertical-align: middle; margin-top: -0.25rem;"></ion-icon>
            </button>
          </div>
          <div class="w-third p-1 pt-2">	
            <button @click="displayType='toDo'"
            :style="(displayType==='toDo')?'border-color: coral' :'border-color: white'"
            class="col-xl-4 col-md-4 col-sm-12 header-tab-button btn bg-clomos rounded p-0 text-nowrap">
              <ion-icon name="time-outline" 
              :style="(displayType==='toDo')?'color: coral':'color: white'"
              class="tx-23" style="vertical-align: middle; margin-top: -0.25rem;"></ion-icon>
            </button>
          </div>
          <div class="w-third p-1 pt-2">	
            <button @click="displayType='done'"
            :style="(displayType==='done')?'border-color: coral' :'border-color: white'"
            class="col-xl-4 col-md-4 col-sm-12 header-tab-button btn bg-clomos rounded p-0 text-nowrap">
              <ion-icon name="checkmark-circle-outline" 
              :style="(displayType==='done')?'color: coral':'color: white'"
              class="tx-23" style="vertical-align: middle; margin-top: -0.25rem;"></ion-icon>
            </button>
          </div>
        </div>

      </div>

      <div class="m-0 p-3 overflow-auto">
        <table id="ordersDataTable" class="display w-100 border-1 p-0 m-0 rounded-3">
          <thead class="bg-clomos-light">
            <tr>
              <th v-for="column in ordersColumns" :key="column.data"
              :width="column.width" class="tx-black fw-medium text-nowrap">
                {{dictionary[column.text][session.user.language]}}
              </th>
            </tr>
          </thead>
        </table>
      </div>

    </div>
  </div>

<!-- MODAL New Order -->
  <NewOrderModal
  v-if="display.newOrderModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :availableCompanies="availableCompanies"
  v-on:toggleNewOrderModal="toggleNewOrderModal">
  </NewOrderModal> 

<!-- MODAL Edit Order
  <ExecuteOrderModal
  v-if="display.editOrderModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :orderId="targetOrderId"
  :availableCompanies="availableCompanies"
  v-on:toggleExecuteOrderModal="toggleExecuteOrderModal">
  </ExecuteOrderModal>
 -->
 <!-- MODAL Execute Order -->
  <div  v-if="display.executeOrderModal"
  id="executeOrderModal"
  class="col-12 justify-content-center pe-6"
  style="padding: 15vh 20%">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">

      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100" >

        <div class="col-12 justify-content-center" style="display: inherit">
          <!--<ion-icon v-if="session.user.bookmark==='devices'" name="bookmark" class="bookmark-button"></ion-icon>
          <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
          <h4 class="tx-left tx-clomos-white text-nowrap mb-2 ps-1 pt-2" style="height:40px;">

            <ion-icon name="arrow-redo-outline" class="tx-27 me-2 align-bottom"></ion-icon>
            {{dictionary["Expedición"][session.user.language]}}
          </h4>
        </div>

      </div>

      <div class="form-group col-12 p-3">
        
        <div class="row ps-2 pe-2">
          <label class="col-12 tx-bold tx-clomos-dark text-nowrap ps-3 pe-3">
            {{dictionary['Dimensiones'][session.user.language]}} <span class="ps-1">(mm)</span>
          </label>
        </div>

        <div class="row">
<!-- ALTO -->
          <div class="form-group col-md-4 col-12 p-1">
            <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
              {{dictionary['Alto'][session.user.language]}}
            </label>
            <div class="col-md-12">
              <input v-model="receptionExecution.volume.height" id="inputOrderExecutionHeight" type="number" 
              class="form-control form-control-line" style="color:black;">
            </div>
          </div>

<!-- ANCHO -->
          <div class="form-group col-md-4 col-12 p-1">
            <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
              {{dictionary['Ancho'][session.user.language]}}
            </label>
            <div class="col-md-12">
              <input v-model="receptionExecution.volume.width" id="inputOrderExecutionWidth" type="number" 
              class="form-control form-control-line" style="color:black;">
            </div>
          </div>

<!-- FONDO -->
          <div class="form-group col-md-4 col-12 p-1">
            <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
              {{dictionary['Fondo'][session.user.language]}}
            </label>
            <div class="col-md-12">
              <input v-model="receptionExecution.volume.depth" id="inputOrderExecutionDepth" type="number" 
              class="form-control form-control-line" style="color:black;">
            </div>
          </div>

        </div>

        <div class="col-12 p-0 ps-2 ms-1 pe-3">
          <hr class="tx-clomos-dark">
        </div>

        <div class="row ps-2 pe-2">
          <label class="col-12 tx-bold tx-clomos-dark text-nowrap ps-3 pe-3">
            {{dictionary['Bultos'][session.user.language]}}
          </label>
        </div>
        
        <div class="row">
<!-- UNIDADES -->
          <div class="form-group col-md-4 col-12 p-1">
            <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
              {{dictionary['Unidades'][session.user.language]}}
            </label>
            <div class="col-md-12">
              <input v-model="receptionExecution.volume.height" id="inputOrderExecutionHeight" type="number" 
              class="form-control form-control-line" style="color:black;">
            </div>
          </div>

        </div>

        <div class="row">

          <div class="col-lg-6 col-md-6 col-12 mt-3">
  <!-- SUBMIT -->
            <button type="button" id="btnSubmitOrder" class="btn btn-clomos w-100"
            @click="this.acceptExecute()">
            {{dictionary['Aceptar'][session.user.language]}}
            </button>
          </div>

          <div class="col-lg-6 col-md-6 col-12 mt-3">
  <!-- DISMISS -->
            <button type="button" id="btnDismissOrder" class="btn btn-clomos w-100"
            @click="this.toggleExecuteOrderModal()">
            {{dictionary['Cancelar'][session.user.language]}}
            </button>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {
  name: "OrdersComponent",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    dataTableOptions: {
      type: Object,
      requiered: true,
    }
  },
  watch: { 
    currentCompany: async function() {
      await this.getUserOrderList();
      this.displayOrdersDataTable();
    }
  },
  data() {
    return {
      ordersColumns: [
        {text:"Albarán", data: "orderId", width: "5%",
          render: (data) => {
            return "<ion-icon name='list-circle-outline' data-id="+data+" class='btnDetailOrder btn tx-24 tx-primary  p-0 m-0'></ion-icon>";
          }
        },
        {text:"Identificador", data: "orderId", width: "20%"},
        {text:"Empresa", data: "orderCompany", width: "20%",
          render: (data) => {
            let companyName = data;
            if (this.availableCompanies.length > 0) {
              for (let company in this.availableCompanies) 
                if (this.availableCompanies[company].nifCif === data ) 
                  companyName = this.availableCompanies[company].name;
            } 
            return companyName;
          }
        },
        {text:"Fecha de ejecución", data: "orderExecutionDateTime", width: "15%"},
        {text:"Fecha de creación", data: "orderCreationDateTime", width: "15%"},
        {text:"Descargar", data: "orderId", width: "5%",
          render: (data) => {
            return "<ion-icon name='download-outline' data-id="+data+" class='btnExecuteOrder btn tx-24 tx-primary  p-0 m-0'></ion-icon>";
          }
        },
      ],
      displayType : 'all',
      targetOrderId: "",
      userOrderList: [],
      display: {
        newOrderModal: false,
        executeOrderModal: false,
        deleteOrderModal: false
      }
    }
  },
  emits: ["setCurrentCompany", "getAvailableCompanies"],
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({params: {currentCompanyNifCif: value}})
      this.$emit('setCurrentCompany',value);
    },
    displayOrdersDataTable() {
      $("#ordersDataTable").DataTable().clear()
      for (let i in this.userOrderList) {
        $("#ordersDataTable").DataTable().rows.add([{
          orderId: this.userOrderList[i].id, 
          orderCompany: this.userOrderList[i].company, 
          orderType: this.userOrderList[i].type,
          orderExecutionDateTime: this.userOrderList[i].executionDateTime, 
          orderCreationDateTime: this.userOrderList[i].creationDateTime, 
          orderFiles: this.userOrderList[i].files, 
        }]);
      }
      $("#ordersDataTable").DataTable().draw();
      this.setButtonFunctions();
     if($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
    },
    async toggleNewOrderModal() {
      if (this.display.newOrderModal) {
        await this.getUserOrderList();
        this.displayOrdersDataTable();
      }
      this.display.newOrderModal = !this.display.newOrderModal;
    },
    async toggleExecuteOrderModal() {
      if (this.display.executeOrderModal) {
        await this.getUserOrderList();
        this.displayOrdersDataTable();
      }
      this.display.executeOrderModal = !this.display.executeOrderModal;
    },
    async toggleRegularizeStockModal() {
      if (this.display.regularizeStockModal) {
        await this.getUserOrderList();
        this.displayStocksDataTable();
      }
      this.display.regularizeStockModal = !this.display.regularizeStockModal;
    },
    async toggleDeleteOrderModal() {
      if (this.display.deleteOrderModal) {
        await this.getUserOrderList();
        this.displayOrdersDataTable();
      }
      this.display.deleteOrderModal = !this.display.deleteOrderModal;
    },
    // BUTTONS
    setButtonFunctions() {
      $(".btnTargetOrder").get().forEach(btn => {
        btn.addEventListener('click', this.onTargetOrder);
      });
      $(".btnExecuteOrder").get().forEach(btn => {
        btn.addEventListener('click', this.onExecuteOrder);
      });
      $(".btnDeleteOrder").get().forEach(btn => {
        btn.addEventListener('click', this.onDeleteOrder);
      });
      $(".btnDetailOrder").get().forEach(btn => {
        btn.addEventListener('click', this.onDetailOrder)
      });
    },
    // GET
    async getUserOrderList(callback) {
      // let request = {
      //   limit: 0,
      //   offset: 0,  
      // };
      // let response = await api.getUserOrderList(request);
      // console.log(response)
      // this.userOrderList = api.parseOrdersFromResponse(response);
      this.userOrderList = [
        {
          id: "001",
          company: "DSI",
          type: "retirada",
          status: "0",
          position: "1A",
          executionDateTime: "3/8/2023",
          creationDateTime: "1/8/2023",
        },
        {
          id: "002",
          company: "DSI",
          type: "pedido",
          status: "1",
          position: "2B",
          executionDateTime: "3/8/2023",
          creationDateTime: "1/8/2023",
        },
        {
          id: "003",
          company: "DSI",
          type: "pedido",
          status: "2",
          position: "3C",
          executionDateTime: "3/8/2023",
          creationDateTime: "1/8/2023",
        },
      ]
      if (callback && typeof callback === "function") callback(this.userOrderList);
    },
    // TARGET 
    onTargetOrder(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetOrderId = event.target.getAttribute('data-id')
    },
    // DETAILS
    onDetailOrder(event) {
      console.log('DETAIL WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetOrderId = event.target.getAttribute('data-id')
      var tr = $(event.target).closest('tr');
      var row = $("#ordersDataTable").DataTable().row(tr);
      if (row.child.isShown()) {
        event.target.name = "list-circle-outline";
        row.child.hide();
        tr.removeClass('shown');
      } 
      else {
        event.target.name = "chevron-up-circle-outline";
        let content = '' +
          '<table id="detailTable'+this.targetOrderId+'" cellspacing="0" border="0" style="width:100%; margin: 0;">' +
            '<tr>' +
              '<td class="tx-bold">Producto</td>' +
              '<td class="tx-bold">Cantidad</td>' +
              '<td class="tx-bold">Talla</td>' +
              '<td class="tx-bold">Color</td>' +
              '<td class="tx-bold">Caducidad</td>' +
              '<td class="tx-bold">Zona</td>' +
            '</tr>' +
            '<tr>' +
              '<td>' + this.targetOrderId +'-AL' + '</td>' +
              '<td>' + '102' + '</td>' +
              '<td>' + 'M' + '</td>' +
              '<td>' + 'Rojo' + '</td>' +
              '<td>' + '-' + '</td>' +
              '<td>' + 'Ambiente' + '</td>' +
            '</tr>' +
            '<tr>' +
              '<td>' + this.targetOrderId +'-AL' + '</td>' +
              '<td>' + '41' + '</td>' +
              '<td>' + 'XL' + '</td>' +
              '<td>' + 'Verde' + '</td>' +
              '<td>' + '-' + '</td>' +
              '<td>' + 'Ambiente' + '</td>' +
            '</tr>' +
          '</table>';
        row.child(content).show();
        document.getElementById('detailTable'+this.targetOrderId).parentNode.style.padding = 0;
        tr.addClass('shown');
      }
    },
    // SET
    onExecuteOrder(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetOrderId = event.target.getAttribute('data-id');
      this.toggleExecuteOrderModal();
    },
    onRegularizeStock(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetOrderId = event.target.getAttribute('data-id');
      this.toggleRegularizeStockModal();
    },
    // DELETE
    async onDeleteOrder(event) {
      if (!this.display.deleteOrderModal) {
        console.log('DELETE WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetOrderId = event.target.getAttribute('data-id');
      }
      else {
        let response = await api.deleteOrder(this.targetOrderId);
        console.log(response);
      }
      this.toggleDeleteOrderModal();
    }
  },
  async created() {
    await this.getUserOrderList()
    this.$emit('getAvailableCompanies',(this.displayOrdersDataTable));
  },
  mounted() {
    $("#ordersDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn ms-auto' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.ordersColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    );
  }
}
</script>
<style>
#executeOrderModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

.btnDetailOrder:hover, .btnExecuteOrder:hover, .btnDeleteOrder:hover {
  color: var(--main-dark);
}
</style>