<template>
<!-- MODAL New Product -->
  <div id="newProductModal"
  class="col-12 justify-content-center pe-6"
  style="padding: 15vh 20%">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">
      
<!-- HEAD New Product -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100">
        <div class="col-10">
          <h4 class="tx-left tx-clomos-white mb-2 ps-1 pt-2">
            <ion-icon name="cube-outline" class="tx-27 me-2 align-bottom"></ion-icon>
            {{dictionary['Nuevo producto'][session.user.language]}}
          </h4>
        </div>

        <div class="col-2 m-0 p-0" style="display: flex; justify-content: end;"
        @click="$emit('toggleNewProductModal')">	
          <ion-icon name="close" class="ms-auto tx-46 tx-white align-bottom"></ion-icon>
        </div>
      </div>

<!-- CONTENT New Product -->
      <div class="col-12 p-0 w-100">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="card-body">

            <form class="form-material border-1 p-3 pt-0 overflow-hidden">
              <div class="container-fluid p-3">
                
                <div class="row">
                  
<!-- NOMBRE -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Nombre'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <input v-model="newProductData.label" id="inputNewProductLabel" type="text" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

<!-- REFERENCIA -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Referencia'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <input v-model="newProductData.reference" id="inputNewProductReference" type="text" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

                </div>

                <div class="row">

<!-- DESCRIPCIÓN -->
                  <div class="form-group col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Descripción'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <textarea v-model="newProductData.description" id="inputNewProductDescription" type="text" rows="1"
                      class="form-control form-control-line" style="color:black;"/>
                    </div>
                  </div>
                
                </div>
              
                <div class="row">
                
<!-- UNIDAD DE MEDIDA -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Unidad de medida'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <select v-model="newProductData.unit"
                      id="inputNewProductUnit"
                      class="form-control form-control-line" style="color:black;">
                        <option value="kilo">
                          {{dictionary["Kilo"][session.user.language]}}
                        </option>
                        <option value="metro">
                          {{dictionary["Metro"][session.user.language]}}
                        </option>
                        <option value="litro">
                          {{dictionary["Litro"][session.user.language]}}
                        </option>
                        <option value="unidad">
                          {{dictionary["Unidad"][session.user.language]}}
                        </option>
                      </select>
                    </div>
                  </div>

<!-- PESO POR UNIDAD -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Peso por unidad'][session.user.language]}} <span class="ps-1">(gr)</span>
                    </label>
                    <div class="col-md-12">
                      <input v-model="newProductData.unitWeight" id="inputNewProductUnitWeight" type="number" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>
          
                </div>

                <div class="row">
                
<!-- APILABLE -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Apilable'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <div id="inputNewProductStackable" 
                      class="form-control border-0 tx-bold w-100"
                      :class="newProductData.stackable"
                      @click="(newProductData.stackable==='true')?newProductData.stackable='false':newProductData.stackable='true'">
                        {{ dictionary[newProductData.stackable][session.user.language] }}
                      </div>
                    </div>
                  </div>

<!-- Alerta de stock -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Alerta de stock'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <input v-model="newProductData.stockWarning" id="inputNewProductWidth" type="number" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>
                
                </div>

                <div class="row">

<!-- TEMERATURA DE CONSERVACIÓN -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Temperatura de conservación'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <select v-model="newProductData.conservationTemperature"
                      id="selectNewProductConservationTemperature"
                      class="form-control form-control-line" style="color:black;">
                        <option value="frio">
                          {{dictionary['Frío'][session.user.language]}}
                        </option>
                        <option value="ambiente">
                          {{dictionary['Ambiente'][session.user.language]}}
                        </option>
                        <option value="ultracongelado">
                          {{dictionary['Ultracongelado'][session.user.language]}}
                        </option>
                      </select>
                    </div>
                  </div>

<!-- PRIORIDAD DE SALIDA -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Prioridad de salida'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <select v-model="newProductData.priority"
                      id="selectNewProductPriority"
                      class="form-control form-control-line" style="color:black;">
                        <option value="entrada">
                          {{dictionary['Fecha de entrada'][session.user.language]}} (FIFO)
                        </option>
                        <option value="salida">
                          {{dictionary['Fecha de salida'][session.user.language]}}
                        </option>
                        <option value="caducidad">
                          {{dictionary['Fecha de caducidad'][session.user.language]}}
                        </option>
                      </select>
                    </div>
                  </div>

                </div>

                <div class="row">

<!-- TIENDA -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Empresa'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <select
                      v-model="newProductData.nifCif"
                      class="form-control form-control-line" style="color:black;">
                        <option v-for="company in availableCompanies" :key="company.nifCif"
                        :v-if="company.nifCif != session.user.nifCif
                        && company.relation != undefined
                        && company.relation.referenceCompany === this.session.user.nifCif"
                        :value="company.nifCif">
                          {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
                        </option>
                      </select>
                    </div>
                  </div>
                  
<!-- CATEGORÍA -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Categoría'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <select v-model="newProductData.category"
                      @change="(newProductData.category==='alimentacion'||newProductData.category==='belleza')?newProductData.priority='caducidad':newProductData.priority='entrada'"
                      id="selectNewProductCategory"
                      class="form-control form-control-line" style="color:black;">
                        <option value="otro">
                          {{dictionary["Otro"][session.user.language]}}
                        </option>
                        <option value="alimentacion">
                          {{dictionary["Alimentación"][session.user.language]}}
                        </option>
                        <option value="bricolaje">
                          {{dictionary["Bricolaje"][session.user.language]}}
                        </option>
                        <option value="belleza">
                          {{dictionary["Belleza"][session.user.language]}}
                        </option>
                        <option value="electrodomesticos">
                          {{dictionary["Electrodomésticos"][session.user.language]}}
                        </option>
                        <option value="menaje">
                          {{dictionary["Menaje"][session.user.language]}}
                        </option>
                        <option value="moda">
                          {{dictionary["Moda"][session.user.language]}}
                        </option>
                        <option value="papeleria">
                          {{dictionary["Papelería"][session.user.language]}}
                        </option>
                      </select>
                    </div>
                  </div>

                </div>

                <div class="col-12 p-0 ps-2 ms-1 pe-3">
                  <hr class="tx-clomos-dark">
                </div>

                <div class="row ps-2 pe-2">
                  <label class="col-12 tx-bold tx-clomos-dark text-nowrap ps-3 pe-3">
                    {{dictionary['Control de stock'][session.user.language]}}
                  </label>
                </div>

                <div class="row">

<!-- FECHA DE CADUCIDAD -->
                  <div v-show="newProductData.category==='alimentacion'
                  ||newProductData.category==='belleza'
                  ||newProductData.category==='otro'" class="form-group col-lg-4 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Fecha de caducidad'][session.user.language]}}
                    </label>
                    <div class="col-12">
                      <div id="inputNewProductExpirationDate" 
                      class="form-control border-0 tx-bold w-100"
                      :class="newProductData.expirationDate"
                      @click="(newProductData.expirationDate==='true')?newProductData.expirationDate='false':newProductData.expirationDate='true'">
                        {{ dictionary[newProductData.expirationDate][session.user.language] }}
                      </div>
                    </div>
                  </div>

<!-- TALLA -->
                  <div v-show="newProductData.category==='moda'
                  ||newProductData.category==='otro'" class="form-group col-lg-4 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Talla'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <div id="inputNewProductSize" 
                      class="form-control border-0 tx-bold w-100"
                      :class="newProductData.size"
                      @click="(newProductData.size==='true')?newProductData.size='false':newProductData.size='true'">
                        {{ dictionary[newProductData.size][session.user.language] }}
                      </div>
                    </div>
                  </div>

<!-- COLOR -->
                  <div v-show="newProductData.category==='moda'
                  ||newProductData.category==='bricolaje'
                  ||newProductData.category==='electrodomesticos'
                  ||newProductData.category==='menaje'
                  ||newProductData.category==='papeleria'
                  ||newProductData.category==='otro'" class="form-group col-lg-4 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Color'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <div id="inputNewProductColor" 
                      class="form-control border-0 tx-bold w-100"
                      :class="newProductData.color"
                      @click="(newProductData.color==='true')?newProductData.color='false':newProductData.color='true'">
                        {{ dictionary[newProductData.color][session.user.language] }}
                      </div>
                    </div>
                  </div>

                </div>

                <div class="col-12 p-0 ps-2 ms-1 pe-3">
                  <hr class="tx-clomos-dark">
                </div>
                
                <div class="row ps-2 pe-2">
                  <label class="col-12 tx-bold tx-clomos-dark text-nowrap ps-3 pe-3">
                    {{dictionary['Dimensiones'][session.user.language]}} <span class="ps-1">(mm)</span>
                  </label>
                </div>

                <div class="row">
<!-- ALTO -->
                  <div class="form-group col-md-4 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Alto'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <input v-model="newProductData.volume.height" id="inputNewProductHeight" type="number" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

<!-- ANCHO -->
                  <div class="form-group col-md-4 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Ancho'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <input v-model="newProductData.volume.width" id="inputNewProductWidth" type="number" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

<!-- FONDO -->
                  <div class="form-group col-md-4 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Fondo'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <input v-model="newProductData.volume.depth" id="inputNewProductDepth" type="number" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

                </div>

                <div class="row">

                  <div class="col-md-6 col-12 m-0 p-0"></div>

<!-- SUBMIT -->
                  <div class="form-group col-lg-6 col-12 p-1">
                    <div class="col-12 mt-4">
                      <button type="button" @click="onCreateNewProduct"
                      id="btnSubmitCreateNewUser" class="btn btn-clomos w-100">
                        {{dictionary['Crear nuevo producto'][session.user.language]}}
                      </button>
                    </div>
                  </div>

                </div>
                
              </div>
            </form>
              
          </div>
        </div>
      </div>
      
    </div>
  </div>

</template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {
  name: "NewProductModal",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    }
  },
  data() {
    return {
      newProductData: {
        nifCif: this.$route.params.currentCompanyNifCif,
        label: "",
        description: "",
        unit: "unidad",
        stockWarning: 0,
        conservationTemperature: "ambiente",
        priority: "entrada",
        stackable: "false",
        category: "otro",
        size: "false",
        color: "false",
        expirationDate: "false",
        unitWeight: 0,
        volume: {
          height: 0,
          width: 0,
          depth: 0
        } 
      },
    }
  },
  emits: ["toggleNewProductModal"],
  methods: {
    // SET
    async onCreateNewProduct() {
      let valid = true;
      if (this.newProductData.label === "") {
        $("#inputNewProductLabel").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.newProductData.description === "") {
        $("#inputNewProductDescription").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.newProductData.unit === "") {
        $("#inputNewProductUnit").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.newProductData.volume.height === 0) {
        $("#inputNewProductHeight").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.newProductData.volume.width === 0) {
        $("#inputNewProductWidht").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.newProductData.volume.depth === 0) {
        $("#inputNewProductDepth").get()[0].classList.add("missing")
        valid = false;
      }
      if (!valid) {
        setTimeout(() => {
          $("#inputNewProductLabel").get()[0].classList.remove("missing")
          $("#inputNewProductDescription").get()[0].classList.remove("missing")
          $("#inputNewProductAmount").get()[0].classList.remove("missing")
          $("#inputNewProductUnit").get()[0].classList.remove("missing")
          $("#inputNewProductHeight").get()[0].classList.remove("missing")
          $("#inputNewProductWidth").get()[0].classList.remove("missing")
          $("#inputNewProductDepth").get()[0].classList.remove("missing")
        }, 3000)
        return;
      }
      let response = await api.postNewProduct(this.newProductData);
      console.log(response);
      if (response["status-code"] === "201") {
        this.$emit('toggleNewProductModal');
        window.alert("El producto ha sido creado satisfactoriamente.");
      }
      else {
        window.alert("Error al crear el producto.");
      }
    },
  },
}
</script>

<style>

#newProductModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>