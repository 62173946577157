<template>
<!-- SECTION Products-->
  <div id="productsSection" class="col-12 justify-content-center pe-3"
  :class="(visibility.sideMenu)?'section-container-sm' :'section-container-lg'">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">
  
    <!-- HEADER Products -->

      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100" >

        <div class="col-lg-6 col-md-12 col-12 justify-content-center" style="display: inherit">
          <!--<ion-icon v-if="session.user.bookmark==='products'" name="bookmark" class="bookmark-button"></ion-icon>
          <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
          <h4 class="tx-left tx-clomos-white text-nowrap mb-2 ps-1 pt-2" style="height:40px;">

            <ion-icon name="cube-outline" class="tx-27 me-2 align-bottom"></ion-icon>
            {{dictionary["Catálogo de productos"][session.user.language]}}
          </h4>
        </div>

        <div class="col-lg-3 col-md-7 col-12 p-2 pb-1 justify-content-center">	
          <select
          :value="currentCompany.nifCif"
          class="form-control h-100 text-nowrap bg-white" 
          style="border: solid 2px white ; color: black;"
          @change="onCurrentCompanyChanged">
            <option v-if="availableCompanies[0]!=undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
            <option v-for="company in availableCompanies" :key="company.nifCif"
            :v-if="company.nifCif != session.user.nifCif
            && company.relation != undefined
            && company.relation.referenceCompany === this.session.user.nifCif"
            :value="company.nifCif">
              {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
            </option>
          </select>
        </div>

        <div class="col-lg-3 col-md-5 col-12 p-2 pb-1">	
          <button id="btnDisplayNewProductModal"
          class="btn btn-clomos bg-white rounded p-1 inner-border fw-normal text-nowrap"
          style="width: 100%; height:100%; vertical-align: middle; border: solid 2px white"
          @click="toggleNewProductModal">
              <ion-icon name="add-circle-outline" class="tx-clomos-dark me-2 tx-20" style="vertical-align: middle; margin-top: -0.25rem;"></ion-icon>
              <span class="ms-auto tx-clomos-dark">{{dictionary["Añadir producto"][session.user.language]}}</span>
          </button>
        </div>
      </div>

      <div class="m-0 p-3 overflow-auto">
        <table id="productsDataTable" class="display w-100 border-1 p-0 m-0 rounded-3">
          <thead class="bg-clomos-light">
            <tr>
              <th v-for="column in usersColumns" :key="column.data"
              :width="column.width" class="tx-black fw-medium text-nowrap">
                {{dictionary[column.text][session.user.language]}}
              </th>
            </tr>
          </thead>
        </table>
      </div>

    </div>
  </div>

<!-- MODAL New Product -->
  <NewProductModal
  v-if="display.newProductModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :availableCompanies="availableCompanies"
  v-on:toggleNewProductModal="toggleNewProductModal">
  </NewProductModal> 

<!-- MODAL Edit Product -->
  <EditProductModal
  v-if="display.editProductModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :productId="targetProductId"
  :availableCompanies="availableCompanies"
  v-on:toggleEditProductModal="toggleEditProductModal">
  </EditProductModal>

<!-- MODAL Delete Text Alert -->
  <TextAlertModal
  v-if="display.deleteProductModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :alertText="dictionary['Producto a eliminar'][session.user.language] + ': ' + targetProductId"
  v-on:acceptAlert="onDeleteProduct"
  v-on:dismissAlert="toggleDeleteProductModal">
  </TextAlertModal>

</template>

<script>
import $ from 'jquery';
import NewProductModal from '@/components/products/NewProductModal';
import EditProductModal from '@/components/products/EditProductModal';
import TextAlertModal from '@/components/other/TextAlertModal';
// Clomos
import api from '@/services/api';

export default {
  name: "ProductsComponent",
  components: { NewProductModal, EditProductModal, TextAlertModal },
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    dataTableOptions: {
      type: Object,
      requiered: true,
    }
  },
  watch: { 
    currentCompany: async function() {
      await this.getProductList();
      this.displayProductsDataTable();
    }
  },
  data() {
    return {
      usersColumns: [
        {text:"Referencia", data: "productRef", width: "10%",
        render: (data) => {
            return "<span data-id="+data+" class='btnTargetProduct "+/*"btn "+*/"tx-bold tx-clomos-midtone text-nowrap'>"+data+"</span>"
          }
        },
        {text:"Imagen", data: "productImgSrc", width: "10%",
          render: (data) => {
            let src;
            if (data != undefined) {
              try {
                src = 'https://apimercaleon.clomos.es/ficheros/ficheros.php?fichero='+data+'&identificadorusuario='+this.session.user.id+'&Token='+localStorage.getItem("MercaLeonAuthToken");
                return '<img style="width:100%;height:auto;margin:auto;" src="'+src+'" />'
              }
              catch(e) { 
                console.warn(e);
              }
            }
              src = require('/src/assets/img/placeholder_image.jpg');
              return '<img style="width:100%;height:auto;margin:auto;" src="'+src+'" />';
          }
        },
        {text:"Nombre", data: "productName", width: "10%"},
        {text:"Empresa", data: "productCompany", width: "10%",
          render: (data) => {
            let companyName = data;
            if (this.availableCompanies.length > 0) {
              for (let company in this.availableCompanies) 
                if (this.availableCompanies[company].nifCif === data ) 
                  companyName = this.availableCompanies[company].name;
            } 
            return companyName;
          }
        },
        {text:"Unidad de medida", data: "productUnit", width: "10%",
          render: (data) => {
            let unit = "";
            switch(data) {
              case "kilo": 
                unit = this.dictionary["Kilo"][this.session.user.language]
                break;
              case "metro": 
                unit = this.dictionary["Metro"][this.session.user.language]
                break;
              case "litro": 
                unit = this.dictionary["Litro"][this.session.user.language]
                break;
              case "unidad": 
                unit = this.dictionary["Unidad"][this.session.user.language]
                break;
              default:
                break;
            }
            return unit;
          }
        },
        {text:"Conservación", data: "productConservationTemperature", width: "10%",
          render: (data) => {
            let conservationValue = "";
            switch(data) {
              case "frio": 
                conservationValue = this.dictionary["Frío"][this.session.user.language]
                break;
              case "ambiente": 
                conservationValue = this.dictionary["Ambiente"][this.session.user.language]
                break;
              case "ultracongelado": 
                conservationValue = this.dictionary["Ultracongelado"][this.session.user.language]
                break;
              default:
                break;
            }
            return conservationValue;
          }
        },
        {text:"Prioridad", data: "productPriority", width: "10%",
          render: (data) => {
            let priority = "";
            switch(data) {
              case "entrada": 
                priority = this.dictionary["Fecha de entrada"][this.session.user.language]
                break;
              case "salida": 
                priority = this.dictionary["Fecha de salida"][this.session.user.language]
                break;
              case "caducidad": 
                priority = this.dictionary["Fecha de caducidad"][this.session.user.language]
                break;
              default:
                break;
            }
            return priority;
          }
        },
        {text:"Alerta de stock", data: "productStockWarning", width: "5%"},
        {text:"Control de stock", data: "productControl", width: "5%",
          render: (data) => {
            // return "<span class='tx-clomos-midtone text-nowrap'>"+this.dictionary["Talla"][this.session.user.language]+": </span>"+this.dictionary[data.size][this.session.user.language]+"</br>"
            // + "<span class='tx-clomos-midtone text-nowrap'>"+this.dictionary["Color"][this.session.user.language]+": </span>"+this.dictionary[data.color][this.session.user.language]+"</br>"
            // + "<span class='tx-clomos-midtone text-nowrap'>"+this.dictionary["Caducidad"][this.session.user.language]+": </span>"+this.dictionary[data.expirationDate][this.session.user.language]+"</br>";
            return "<img src='/icons/icon-size.png' style='width: 3rem; padding: 0 10px;' class='"+((data.size)?"opacity-100":"opacity-25")+"' alt='"+this.dictionary["Talla"][this.session.user.language]+"' title='"+this.dictionary["Talla"][this.session.user.language]+"'/>"
            + "<img src='/icons/icon-color.png' style='width: 3rem; padding: 0 10px;' class='"+((data.color)?"opacity-100":"opacity-25")+"' alt='"+this.dictionary["Color"][this.session.user.language]+"' title='"+this.dictionary["Color"][this.session.user.language]+"'/>"
            + "<img src='/icons/icon-expiration.png' style='width: 3rem; padding: 0 10px;' class='"+((data.expirationDate)?"opacity-100":"opacity-25")+"' alt='"+this.dictionary["Caducidad"][this.session.user.language]+"' title='"+this.dictionary["Caducidad"][this.session.user.language]+"'/>"
          }
        },
        {text:"Editar", data: "productId", width: "5%",
          render: (data) => {
            return "<ion-icon name='create-outline' data-id="+data+" class='btnEditProduct btn tx-24 tx-primary p-0 m-0'></ion-icon>";
          }
        },
        {text:"Eliminar", data: "productId", width: "5%",
          render: (data) => {
            return "<ion-icon name='trash-outline' data-id="+data+" class='btnDeleteProduct btn tx-24 tx-primary p-0 m-0'></ion-icon>";
          }
        }
      ],
      targetProductId: this.$route.params.targetEntity,
      productList: [],
      display: {
        newProductModal: false,
        editProductModal: false,
        deleteProductModal: false
      }
    }
  },
  emits: ["setCurrentCompany", "getAvailableCompanies"],
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$emit('setCurrentCompany',value);
    },
    displayProductsDataTable() {
      let currentPage = $("#productsDataTable").DataTable().page();
      console.log(currentPage);
      $("#productsDataTable").DataTable().clear()
      for (let i in this.productList) {
        $("#productsDataTable").DataTable().rows.add([{
          productId: this.productList[i].id, 
          productRef: this.productList[i].variables.dynamic.referencia.value, 
          productName: this.productList[i].label, 
          productCompany: this.productList[i].nifCif, 
          productUnit: this.productList[i].variables.dynamic.unidad.value, 
          productConservationTemperature: this.productList[i].variables.dynamic.temperaturaConservacion.value, 
          productPriority: this.productList[i].variables.dynamic.prioridadSalida.value, 
          productStockWarning: Number(this.productList[i].variables.analog.alertaStock.value).toFixed(0), 
          productImgSrc: (this.productList[i].variables.dynamic.urlImagen!=undefined)?this.productList[i].variables.dynamic.urlImagen.value:undefined,
          productControl: {
            size: Boolean(this.productList[i].variables.dynamic.talla!=undefined),
            color: Boolean(this.productList[i].variables.dynamic.color!=undefined),
            expirationDate: Boolean(this.productList[i].variables.dynamic.fechaCaducidad!=undefined)
          }, 
        }]);
      }
      $("#productsDataTable").DataTable().page(currentPage);
      $("#productsDataTable").DataTable().draw();
      this.setButtonFunctions();
     if($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
    },
    async toggleNewProductModal() {
      if (this.display.newProductModal) {
        await this.getProductList();
        this.displayProductsDataTable();
      }
      this.display.newProductModal = !this.display.newProductModal;
    },
    async toggleEditProductModal() {
      if (this.display.editProductModal) {
        await this.getProductList();
        this.displayProductsDataTable();
      }
      this.display.editProductModal = !this.display.editProductModal;
    },
    async toggleDeleteProductModal() {
      if (this.display.deleteProductModal) {
        await this.getProductList();
        this.displayProductsDataTable();
      }
      this.display.deleteProductModal = !this.display.deleteProductModal;
    },
    // BUTTONS
    setButtonFunctions() {
      $(".btnTargetProduct").get().forEach(btn => {
        btn.addEventListener('click', this.onTargetProduct);
      });
      $(".btnEditProduct").get().forEach(btn => {
        btn.addEventListener('click', this.onEditProduct);
      });
      $(".btnRegularizeStock").get().forEach(btn => {
        btn.addEventListener('click', this.onRegularizeStock);
      });
      $(".btnDeleteProduct").get().forEach(btn => {
        btn.addEventListener('click', this.onDeleteProduct);
      });
    },
    // GET
    async getProductList(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getProductList(request);
      this.productList = api.parseProductsFromResponse(response);
      if (callback && typeof callback === "function") callback(this.productList);
    },
    // TARGET 
    onTargetProduct(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetProductId = event.target.getAttribute('data-id');
      this.$router.replace({params: {targetEntity: this.targetProductId}})
    },
    // SET
    onEditProduct(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetProductId = event.target.getAttribute('data-id');
      this.$router.replace({params: {targetEntity: this.targetProductId}})
      this.toggleEditProductModal();
    },
    // DELETE
    async onDeleteProduct(event) {
      if (!this.display.deleteProductModal) {
        console.log('DELETE WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetProductId = event.target.getAttribute('data-id');
        this.$router.replace({params: {targetEntity: this.targetProductId}})
      }
      else {
        let response = await api.deleteProduct(this.targetProductId);
        console.log(response);
      }
      this.toggleDeleteProductModal();
    }
  },
  async created() {
    await this.getProductList();
    this.$emit('getAvailableCompanies',(this.displayProductsDataTable));
  },
  mounted() {
    $("#productsDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn ms-auto' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.usersColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    );
  }
}
</script>

<style>
/*.btnTargetProduct:hover,*/ .btnEditProduct:hover, .btnRegularizeStock:hover, .btnDeleteProduct:hover {
  color: var(--main-dark);
}
</style>