<template>
  <div class="nav-top-menu bg-clomos"> 
    <!-- Logo -->
      <div :class="(visibility.sideMenu)?'top-menu-logo':'top-menu-logo-sm'"
      @click="$router.replace({params: {currentDisplay: 'dashboard'} })"></div>

    <!-- Menu lateral -->
    <div class="top-menu-side-menu-toggle">
      <ion-icon name="menu" id="toggleSideMenuButton" class="top-menu-icon tx-white" style="font-size: 25px"
      @click="$emit('toggleSideMenu')"></ion-icon>
    </div>

    <!-- Selector de Sistema -->
    <div class="top-menu-selector" v-if="1===0">	
      <select
      :value="currentCompany.nifCif"
      class="form-control pt-0 pb-0 h-100 text-nowrap" 
      style="border: solid 1px white"
      @change="onCurrentCompanyChanged">
        <option v-if="availableCompanies[0]!=undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
        <option v-for="company in availableCompanies" :key="company.nifCif"
        :v-if="company.nifCif != session.user.nifCif
        && company.relation != undefined
        && company.relation.referenceCompany === this.session.user.nifCif"
        :value="company.nifCif">
          {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
        </option>
      </select>
    </div>

    <!-- Notificaciones -->
    <div class="top-menu-warnings">
      <ion-icon name="alert-circle" class="tx-white" style="font-size: 25px"
      @click="onTopMenuItemClicked('warnings')"></ion-icon>
    </div>	

      <!-- Nombre de usuario -->
    <div class="top-menu-user">
      <div class="top-menu-profile-button"
      @click="onTopMenuItemClicked('profile')">
        <ion-icon name="person" class="top-menu-icon tx-white mt-2" style="font-size: 25px"></ion-icon>
      </div>
        <a class="top-menu-user-mail tx-white text-nowrap ms-3" style="align-self: center;" 
        @click="onTopMenuItemClicked('profile')">
          {{session.user.name+" "+session.user.lastName}}
        </a>
    </div>

  </div>
</template>

<script>
export default {
  name: "TopMenuComponent",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    }
  },
  watch: {
    currentCompany: function() {
      if ((this.userCompany.name === undefined && this.currentCompany.name != undefined)
      || (this.userCompany.name === undefined && this.currentCompany.name != undefined)
      || (this.userCompany.name != this.currentCompany.name)) {
        this.userCompany.name = this.currentCompany.name;
        this.userCompany.nifCif = this.currentCompany.nifCif;
      }
    }
  },
  emits: ["toggleSideMenu", "setCurrentCompany"],
  data() {
    return {
      userCompany: {}
    }
  },
  methods: {
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({params: {currentCompanyNifCif: value}})
      this.$emit('setCurrentCompany',value);
    },
    // NAVIGATE 
    onTopMenuItemClicked(page) {
      if(this.visibility.sideMenu && window.innerWidth <= 767)
        this.$emit('toggleSideMenu')
      this.$router.replace({params: {currentDisplay: page} })
    },
  }
}
</script>

<style>
.nav-top-menu {
  height: 4.5rem;
  width: 100%;
  color: #FFF;
  border-bottom: solid 0.5px rgba(255, 255, 255, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  font-weight:400;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.top-menu-icon:hover{
  cursor: pointer;
  opacity: 0.75;
  transition: all 0.3s;
}

.top-menu-logo {
  background-image: url("@/assets/img/logo-clomos.png");
  background-repeat:no-repeat;
  background-size:contain;
  background-position-y: center;
  cursor: pointer;
  width: 11.25rem;
  min-width: 3.3rem;
  height: 70%;
  padding: 0;
  margin: auto 2.75rem auto 2.75rem;
  transition: all 0.3s;
}

.top-menu-logo-sm {
  background-image: url("@/assets/img/logo-clomos-sm.png");
  background-repeat:no-repeat;
  background-size:contain;
  background-position-y: center;
  cursor: pointer;
  width: 3.3rem;
  min-width: 3.3rem;
  height: 70%;
  padding: 0;
  margin: auto 0.1rem auto 1rem;
  transition: all 0.3s;
}

.top-menu-side-menu-toggle{
  display: flex;
  justify-content: center;
  align-self: center;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  transition: all 0.3s;
}

.top-menu-selector{
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 200px;
  min-width: 200px;
  margin-right: 1.25rem;
  transition: all 0.3s;
}

.top-menu-warnings{
  display: flex;
  justify-content: center;
  float: left;
  align-self: center;
  margin-left: auto;
  margin-right: 1.25rem;
  transition: all 0.3s;
}

.top-menu-user{
  display: flex;
  justify-content: center;
  float: left;
  align-self: center;
  margin-right: 1rem;
  transition: all 0.3s;
}

.top-menu-profile-button{
  display: flex;
  justify-content: center;
  width: 2.75rem;
  height: 2.75rem;
  border: solid 1px white;
  border-radius: 3rem;
  transition: all 0.3s;
}

@media (max-width: 767px) {
  .top-menu-logo {
    background-image: url("@/assets/img/logo-clomos-sm.png");
    background-repeat:no-repeat;
    background-size:contain;
    background-position-y: center;
    cursor: pointer;
    width: 3.3rem;
    min-width: 3.3rem;
    height: 70%;
    padding: 0;
    margin: auto 0.1rem auto 1rem;
    transition: all 0.3s;
  }

  .top-menu-user-mail{
    display: none;
    transition: all 0.3s;
}
}

</style>