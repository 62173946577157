<template>
  <div class="col-md-12 col-12 justify-content-center pe-3"
  :class="(visibility.sideMenu)?'section-container-sm' :'section-container-lg'"
  style="padding-bottom: 3rem">
    <div class="col-md-12 col-12 p-0 m-0 bg-caja section-container rounded-5">

<!-- HEAD -->
      <div class="row col-12 m-0 p-0 tx-white bg-clomos rounded-top-5 pb-1 h-100" >
        <div class="col-lg-9 col-md-12 col-12">
          <h4 class="tx-left tx-clomos-white mb-2 ps-1 pt-2" style="height:40px;">
            <ion-icon name="person" class="tx-27 me-2 align-bottom"></ion-icon>
            {{dictionary['Perfil'][session.user.language]}}
          </h4>
        </div>
      </div>

<!-- Content -->
      <div class="col-md-12 justify-content-center d-flex flex-column">

<!-- ROW 1 -->
        <div class="row">

<!-- FORM Password -->
          <div class="col-12 p-0 w-100">
            <div class="p-3 pb-2">
              <div class="card-body">

                <form class="form-material p-2 bg-clomos-midtone rounded">
                  <div class="container-fluid p-0">
                    <div class="row p-0">

<!-- CONTRASEÑA -->
                      <div class="form-group col-xl-3 col-md-6 col-12 p-0">
                        <label class="col-12 tx-white tx-bold text-nowrap">
                          {{dictionary['Contraseña anterior'][session.user.language]}}
                          </label>
                        <div class="col-12">
                          <input v-model="formChangePass.oldPassword" id="inputMyOldPass" type="password" autocomplete="off" 
                          class="form-control form-control-line" style="color:black; background-color: #EEEEEE">
                        </div>
                      </div>

<!-- NUEVA-CONTRASEÑA -->
                      <div class="form-group col-xl-3 col-md-6 col-12  p-0">
                        <label class="col-12 tx-white tx-bold text-nowrap">
                          {{dictionary['Nueva contraseña'][session.user.language]}}
                        </label>
                        <div class="col-12">
                          <input v-model="formChangePass.newPassword" id="inputMyNewPass" type="password" autocomplete="off" 
                          class="form-control form-control-line" style="color:black; background-color: #EEEEEE">
                        </div>
                      </div>

<!-- RE-NUEVA-CONTRASEÑA -->
                      <div class="form-group col-xl-3 col-md-6 col-12  p-0">
                        <label class="col-12 tx-white tx-bold text-nowrap">
                          {{dictionary['Repite la contraseña'][session.user.language]}}
                        </label>
                        <div class="col-12">
                          <input v-model="formChangePass.newPasswordRe" id="inputMyNewPassRe" type="password" autocomplete="off" 
                          class="form-control form-conwhite tx-boldtrol-line" style="color:black; background-color: #EEEEEE">
                        </div>
                      </div>

                      <div class="form-group col-xl-3 col-md-6 col-12 p-0">
                        <div class="col-12 mt-4">
                          <button type="button" @click="onChangePassword"
                          class="btn btn-dark w-100" id="btnGuardarPass">
                            {{dictionary['Restablecer contraseña'][session.user.language]}}
                          </button>
                        </div>
                      </div>

                    </div>
                  </div>
                </form>

<!-- FORM Password (End) -->

              </div>
            </div>
          </div>

        </div>

<!-- ROW 2 -->
        <div class="row p-4 pt-0">

<!-- FORM My Profile -->
          <div class="col-xl-7 col-md-12 col-12 p-2 pe-3" id="cardMyProfile">
            <h5 class="bg-clomos-midtone rounded-top-3 p-1 m-0 tx-clomos-white">
              {{dictionary['Mi perfil'][session.user.language]}}
            </h5>

            <form class="form-material border-1 rounded-bottom-3 p-3 pt-0">
              <div class="container-fluid p-3">
                
                <div class="row">
                  
<!-- MI NOMBRE -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Nombre'][session.user.language]}}
                    </label>
                    <div class="col-12">
                      <input v-model="myProfileData.name" id="inputMyUserName" type="text" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

<!-- MI APELLIDOS -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Apellidos'][session.user.language]}}
                    </label>
                    <div class="col-12">
                      <input v-model="myProfileData.lastName" id="inputMyUserLastName" type="text" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

                </div>
              
                <div class="row">
                
<!-- MI CORREO -->
                  <div class="form-group col-md-12 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Correo electrónico'][session.user.language]}}
                    </label>
                    <div class="col-12">
                      <input v-model="myProfileData.email" id="inputMyUserEmail" type="text" 
                      class="form-control form-control-line" style="color:black;" disabled>
                    </div>
                  </div>
                
                </div>

                
<!-- MI CORREO RE -->
<!--
                <div class="row">
                  <div class="form-group col-md-12 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Repetir correo'][session.user.language]}}
                    </label>
                    <div class="col-12">
                      <input v-model="myProfileData.emailRe" id="inputMyUserEmailRe" type="text" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>
                
                </div>
-->
                <div class="row">

<!-- MI ROL -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Rol'][session.user.language]}}
                    </label>
                    <div class="col-12">
                      <select v-model="myProfileData.rol"
                      id="selectMyUserRol" disabled
                      class="form-control form-control-line" style="color:black;">
                        <option value="255"> 
                          {{dictionary['Administrador general'][session.user.language]}}
                        </option>
                        <option value="128">
                          {{dictionary['Administrador de la empresa'][session.user.language]}}
                        </option>
                        <option value="32">
                          {{dictionary['Autorizado'][session.user.language]+"/"+dictionary['Editor'][session.user.language]}}
                        </option>
                        <option value="2">
                          {{dictionary['Empleado'][session.user.language]+"/"+dictionary['Visor'][session.user.language]}}
                        </option>
                        <option value="1">
                          {{dictionary['Agente'][session.user.language]+"/"+dictionary['Representante'][session.user.language]}}
                        </option>
                      </select>
                    </div>
                  </div>

<!-- MI TELÉFONO -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Teléfono'][session.user.language]}}
                    </label>
                    <div class="col-12 d-flex flex-row">
                      <div class="w-100">
                        <input v-model="myProfileData.telephone" id="inputMyUserTelephone"
                        class="form-control form-control-line" style="color:black;">
                      </div>
                    </div>
                      
                  </div>
                
                </div>

                <div class="row">

<!-- MI PROVINCIA -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Provincia'][session.user.language]}}
                    </label>
                    <div class="col-12">
                      <input v-model="myProfileData.province" id="inputMyUserProvince" type="text" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

<!-- MI PAÍS -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['País'][session.user.language]}}
                    </label>
                    <div class="col-sm-12">
                      <select v-model="myProfileData.country"
                      id="selectMyUserCountry"
                      class="form-control form-control-line" style="color:black;">
                        <option v-for="country in countries" :key="country['code']" :value="country['code']">
                          {{country['name'][session.user.language]}}
                        </option>
                      </select>
                    </div>
                  </div>

                </div>

                <div class="row">

<!-- DIRECCIÓN -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Dirección'][session.user.language]}} 1
                    </label>
                    <div class="col-12">
                      <textarea v-model="myProfileData.adress1" id="inputMyUserAdress1" name="adress" rows="1"
                      class="form-control" style="color: black; padding: 0.7rem;"/> 
                    </div>
                  </div>

                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Dirección'][session.user.language]}} 2
                    </label>
                    <div class="col-12">
                      <textarea v-model="myProfileData.adress2" id="inputMyUserAdress2" name="adress" rows="1"
                      class="form-control" style="color: black; padding: 0.7rem;"/> 
                    </div>
                  </div>
                
                </div>

                <div class="col-12 p-0 ps-2 ms-1 pe-3">
                  <hr class="tx-clomos-dark">
                </div>

                <div class="row">

                  <div class="form-group col-md-6 col-12 p-1 pt-0">
                    <label class="col-12 tx-clomos-dark tx-bold">
                      {{dictionary['Preferencias'][session.user.language]}}:
                    </label>
                  </div>
                  
                </div>

                <div class="row">

<!-- MI IDIOMA -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Idioma'][session.user.language]}}
                    </label>
                    <div class="col-12">
                      <select v-model="myProfileData.language"
                      id="selectMyUserLanguage"
                      class="form-control form-control-line" style="color:black;">
                        <option value="es">
                          {{dictionary['Español'][session.user.language]}}
                        </option>
                        <option value="en">
                          {{dictionary['Inglés'][session.user.language]}}
                        </option>
                      </select>
                    </div>
                  </div>

<!-- MI SEPARADOR DE COMAS CSV -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Separador de comas CSV'][session.user.language]}}
                    </label>
                    <div class="col-sm-12">
                      <select v-model="myProfileData.fieldSeparator"
                      id="selectMyFieldSeparator"
                      class="form-control form-control-line" style="color:black;">
                        <option value=",">
                          {{dictionary['Coma'][session.user.language]}} ","
                        </option>
                        <option value=";">
                          {{dictionary['Punto y coma'][session.user.language]}} ";"
                        </option>
                      </select>
                    </div>
                  </div>

                </div>

                <div class="row">

<!-- MI TEMPERATURA -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Temperatura'][session.user.language]}}
                    </label>
                    <div class="col-sm-12">
                      <select v-model="myProfileData.unitTemperature"
                      id="selectMyUnitTemperature"
                      class="form-control form-control-line" style="color:black;">
                        <option value="C">Cº (Celsius)</option>
                        <option value="F">Fº (Fahrenheit)</option>
                      </select>
                    </div>
                  </div>
                  
<!-- MI FORMATO -->
                  <div class="form-group col-md-6 col-12 p-1">
                      <label class="col-12 tx-bold tx-clomos-midtone">
                        {{dictionary['Formato'][session.user.language]}}
                      </label>
                      <div class="col-12">
                        <select v-model='myProfileData.dateTimeFormat'
                        id="selectMyDateTimeFormat"
                        class="form-control form-control-line" style="color:black;">
                          <option value="DD/MM/YYYY">DD/MM/YYYY</option>
                          <option value="MM/DD/YYYY">MM/DD/YYYY</option>
                        </select>
                      </div>
                    </div>

                </div>

                <div class="row">

<!-- MI ZONA HORARIA -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Zona horaria'][session.user.language]}}
                    </label>
                    <div class="col-12">
                      <select v-model="myProfileData.dateTimeZone"
                      id="selectMyDateTimeZone"
                      class="form-control form-control-line" style="color:black;">
                        <option v-for="timeZone in timeZones" :key="timeZone['value']" :value="timeZone['value']">
                          {{timeZone['value'].replace("_"," ")}}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group col-md-6 col-12 p-1">
                    <div class="col-12 mt-4">
                      <!-- SUBMIT -->
                      <button type="button" @click="setMyUserData"
                      class="btn btn-clomos w-100" id="btnGuardarPerfil">
                        {{dictionary['Actualizar perfil'][session.user.language]}}
                      </button>
                    </div>
                  </div>

                </div>
<!-- FORM My Profile (End) -->
                
              </div>
            </form>
          </div>

<!-- FORM My Company -->
          <div class="col-xl-5 col-md-12 col-12 p-2 ps-3" id="cardMyCompany">
            
            <h5 class="bg-clomos-midtone p-1 mb-0 rounded-top-3 tx-clomos-white">
              {{dictionary['Mi empresa'][session.user.language]}}
            </h5>

            <form class="form-material border-1 rounded-bottom-3 p-3 pt-0">
              
              <div class="container-fluid p-3">

                <div class="row">

<!-- NIF/CIF MI COMPAÑÍA-->
                  <div class="form-group col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['NIF/CIF'][session.user.language]}}
                    </label>
                    <div class="col-12">
                      <input v-model="myCompanyData.nifCif" disabled
                      id="nifCif" type="text"
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

                </div>

                <div class="row">

<!-- NOMBRE MI COMPAÑÍA-->
                  <div class="form-group col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Nombre de la empresa'][session.user.language]}}
                    </label>
                    <div class="col-12">
                      <input v-model="myCompanyData.name" :disabled="Number(session.user.rol)<128"
                      id="inputMyCompanyName" type="text"
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

                </div>
              
                <div class="row">

<!-- DIRECCIÓN MI COMPAÑÍA-->
                  <div class="form-group col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Dirección'][session.user.language]}}
                    </label>
                    <div class="col-12">
                      <input v-model="myCompanyData.adress" :disabled="Number(session.user.rol)<128"
                      id="inputMyCompanyAdress" type="text"
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

                </div>
              
                <div class="row">

<!-- CORREO MI COMPAÑÍA-->
                  <div class="form-group col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['E-mail'][session.user.language]}}
                    </label>
                    <div class="col-12">
                      <input v-model="myCompanyData.email" :disabled="Number(session.user.rol)<128"
                      id="inputMyCompanyEmail" type="text"
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

                </div>

                <div class="row">

<!-- TELÉFONO MI COMPAÑÍA-->
                  <div class="form-group col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Teléfono'][session.user.language]}}
                    </label>
                    <div class="col-12">
                      <input v-model="myCompanyData.telephone" :disabled="Number(session.user.rol)<128"
                      id="inputMyCompanyTelephone" type="text"
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

                </div>
<!-- PAÍS -->
                <div class="row">

                  <div class="form-group col-12 p-1" style="text-align: left;">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary["País"][session.user.language]}}
                    </label>
                    <div class="col-12">
                      <select v-model="myCompanyData.country" id="selectMyCompanyCountry" :disabled="Number(session.user.rol)<128"
                      class="form-control form-control-line"  style="color: black;">
                        <option v-for="country in countries" :key="country['code']" :value="country['code']">
                          {{country['name'][session.user.language]}}
                        </option>
                      </select>
                    </div>
                  </div>

                </div>

<!-- SECTOR -->
                <div class="row">

                  <div class="form-group col-12 p-1 pb-0" style="text-align: left;">								
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary["Sector"][session.user.language]}}
                    </label>
                    <div class="col-12">
                      <select v-model="myCompanyData.sector" id="selectMyCompanySector" :disabled="Number(session.user.rol)<128"
                      class="form-control form-control-line" style="color: black;">
                        <option value="aeronautica">
                          {{dictionary["Aeronáutica"][session.user.language]}}
                        </option>
                        <option value="agroalimentario">
                          {{dictionary["Agroalimentario"][session.user.language]}}
                        </option>
                        <option value="automotriz">
                          {{dictionary["Automotriz"][session.user.language]}}
                        </option>
                        <option value="construccion">
                          {{dictionary["Obra civil"][session.user.language]}}
                        </option>
                        <option value="quimica">
                          {{dictionary["Química"][session.user.language]}}
                        </option>
                        <option value="militar">
                          {{dictionary["Defensa/Militar"][session.user.language]}}
                        </option>
                        <option value="electronica">
                          {{dictionary["Electrónica"][session.user.language]}}
                        </option>
                        <option value="energia">
                          {{dictionary["Energía"][session.user.language]}}
                        </option>
                        <option value="it">
                          {{dictionary["Tecnologías de la información"][session.user.language]}}
                        </option>
                        <option value="industria 4.0">
                          {{dictionary["Industria 4.0"][session.user.language]}}
                        </option>
                        <option value="farma-medico">
                          {{dictionary["Farma-médico"][session.user.language]}}
                        </option>
                        <option value="logistica">
                          {{dictionary["Transporte y logística"][session.user.language]}}
                        </option>
                        <option value="almacenamiento">
                          {{dictionary["Manejo y almacenamiento de materiales"][session.user.language]}}
                        </option>
                        <option value="canteras">
                          {{dictionary["Canteras, cemento, mármoles"][session.user.language]}}
                        </option>
                        <option value="papeleras">
                          {{dictionary["Fábricas de papel y celulosa"][session.user.language]}}
                        </option>
                        <option value="gas">
                          {{dictionary["Petróleo y gas"][session.user.language]}}
                        </option>
                        <option value="puertos">
                          {{dictionary["Industria Portuaria"][session.user.language]}}
                        </option>
                        <option value="puertos deportivos">
                          {{dictionary["Puertos deportivos"][session.user.language]}}
                        </option>
                        <option value="astilleros">
                          {{dictionary["Astilleros"][session.user.language]}}
                        </option>
                        <option value="telecomunicaciones">
                          {{dictionary["Telecomunicaciones"][session.user.language]}}
                        </option>
                        <option value="hierro y acero">
                          {{dictionary["Hierro y acero"][session.user.language]}}
                        </option>
                        <option value="reciclaje">
                          {{dictionary["Tratamiento de residuos/Reciclaje"][session.user.language]}}
                        </option>
                        <option value="otro">
                          {{dictionary["Otro"][session.user.language]}}
                        </option>
                      </select>
                    </div>
                  </div>
                  
                </div>

                <div v-if="Number(session.user.rol)>128" class="form-group col-12" style="padding:1rem 0.8rem 0;">
<!-- SUBMIT -->
                  <button type="button" @click="setMyCompanyData"
                  class="btn btn-clomos w-100" id="btnEditMyCompany">
                    {{dictionary['Editar compañía'][session.user.language]}}
                  </button>
                </div>

                <div class="col-12 p-0 ps-2 ms-1 pe-3">
                  <hr class="tx-clomos-dark">
                </div>

<!-- FORM Company Logo-->
              <div class="form-group col-12 p-2 pt-0 pb-0">

                <div class="col-12 p-1 pt-0 pb-0 overflow-hidden">

                  <div class="justify-content-center bg-clomos-midtone rounded-5">
                    
                    <label class=" col-12 p-3 pb-0 tx-white tx-bold text-nowrap">
                      {{dictionary["Logotipo de la empresa"][session.user.language].toUpperCase()}}
                    </label>
                    <label class=" col-12 p-3 pt-0 tx-white tx-light text-nowrap">
                      {{dictionary["Tamaño recomendado"][session.user.language]+': 200x200px'}}
                    </label>

                    <img id="logo" class="w-100" style="max-width: 100px; width: auto; padding: 1rem;" :src="displayMyCompanyImage(myCompanyData.logoSrc)">

                    <div id="logoUploadFormContainer" class="w-100 p-3 pt-0" style="display: flex; flex-direction: column; justify-content: space-around; gap: 1rem;">
                      <input type="file" id="logoUploadSrc" class=" w-100 file-button btn btn-secondary rounded" style="height: 3rem; min-width: 180px;"
                      @change="onChangeMyCompanyImage" accept=".png">

                      <div v-if="myCompanyImageForm.file!=''" class="myCompanyImagePreview">
                        <div class="d-flex flex-column">
                          <button type="button" title="Remove file" @click="onRemoveMyNewCompanyImage()" style="line-height: 0;" class="p-1 m-0 rounded ms-auto">
                            <ion-icon name="close" class="tx-18 tx-black align-bottom"></ion-icon>
                          </button>
                          <img class="myCompanyImagePreview pt-3" :src="displayMyCompanyThumbnail(myCompanyImageForm.file)" />
                        </div>
                      </div>

                      <button type="button" id="logoUploadSubmit" class=" w-100 file-button btn btn-dark rounded" style="height: 3rem; min-width: 180px;"
                      @click="setMyCompanyImage">
                        <ion-icon name="log-in-outline" class="me-3"></ion-icon>
                        {{dictionary["Guardar archivo"][session.user.language]}}
                      </button>
                    </div>

                    </div>

                  </div>

                </div>
              
              </div>


            </form>

          </div>

<!-- FOOT -->
        </div>	
      </div>
    </div>
  </div>

</template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {
  name: "ProfileComponent",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    }
  },
  watch: { 
    currentCompany: async function(oldCompany, newCompany) {
      if (oldCompany.nifCif != newCompany.nifCif) {
        this.getMyProfileData();
        await this.getMyCompanyData();
      }
    }
  },
  data() {
    return {
      formChangePass: {
        oldPassword: "",
        newPassword: "",
        newPasswordRe: ""
      },
      myProfileData: {
        id: "",
        name: "",
        lastName: "",
        email: "",
        emailRe: "",
        adress1: "",
        adress2: "",
        province: "",
        country: "",
        telephone: "",
        rol: "",
        language: "",
        unitTemperature: "",
        unitDistance: "",
        dateTimeZone: "",
        dateTimeFormat: "",
        fieldSeparator: ""
      },
      myCompanyData: {
        nifCif: "",
        name: "",
        email: "",
        telephone: "",
        country: "",
        adress: "",
        sector: "",
        logoSrc: ""
      },
      myCompanyImageForm: {
        nifCif: "",
        src: "",
        file: ""
      },
    }
  },
  emits:[ 'getCurrentUserData', 'getCurrentCompanyData' ],
  methods: {
// DISPLAY
    displayMyCompanyImage(src) {
      if (src!=undefined && src!='') {
        try {
          return 'https://apilockers.clomos.es/ficheros/ficheros.php?fichero='+src+'&identificadorusuario='+this.session.user.id+'&Token='+localStorage.getItem("LockersAuthToken");
        }
        catch(e) { 
          console.warn(e);
        }
      }
      return require('/src/assets/img/logo-clomos-sm.png'); 
    },
    displayMyCompanyThumbnail(file) {
      if (file === '' || file === undefined) return "";
      let fileSrc = URL.createObjectURL(file);
        setTimeout(() => {
            URL.revokeObjectURL(fileSrc);
        }, 1000);
      return fileSrc;
    },
    onChangeMyCompanyImage(event) {
      if (event.target.files[0] != undefined && event.target.files[0].size > 2097152) {
        alert("El tamaño del fichero debe ser inferior a 2Mb.");
        return;
      }
      this.myCompanyImageForm.nifCif = this.myCompanyData.nifCif;
      this.myCompanyImageForm.file = event.target.files[0];
      this.myCompanyImageForm.src = event.target.files[0].name;
      },
    onRemoveMyNewCompanyImage() {
      this.myCompanyImageForm.nifCif = "";
      this.myCompanyImageForm.file = "";
      this.myCompanyImageForm.src = "";
    },
// GET MyProfile
    getMyProfileData(callback) {
      this.$emit('getCurrentUserData', () => {
        this.myProfileData = {
          id: this.session.user.id,
          name: this.session.user.name,
          lastName: this.session.user.lastName,
          nifCif: this.session.user.nifCif,
          email: this.session.user.email,
          adress1: this.session.user.adress1,
          adress2: this.session.user.adress2,
          province: this.session.user.province,
          country: this.session.user.country,
          telephone: this.session.user.telephone,
          rol: this.session.user.rol,
          language: this.session.user.language,
          unitTemperature: this.session.user.unitTemperature,
          unitDistance: this.session.user.unitDistance,
          dateTimeZone: this.session.user.dateTimeZone,
          dateTimeFormat: this.session.user.dateTimeFormat,
          fieldSeparator: this.session.user.fieldSeparator,
        }
      });
      if (callback && typeof callback === "function") callback(this.myProfileData);
    },
// GET MyCompany
    async getMyCompanyData(callback) {
      let response = await api.getCompanyData(this.session.user.nifCif);
      this.myCompanyData = api.parseCompanyFromResponse(response);
      if (callback && typeof callback === "function") callback(this.myCompanyData);
    },
// SET MyPassword
    async onChangePassword() {
      let valid = true; 
      if (this.formChangePass.newPassword != this.formChangePass.newPasswordRe) {
        $("#inputMyNewPass").get()[0].classList.add("missing")
        $("#inputMyNewPassRe").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.formChangePass.oldPassword === "") {
        $("#inputMyOldPass").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.formChangePass.newPassword === "") {
        $("#inputMyNewPass").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.formChangePass.newPasswordRe === "") {
        $("#inputMyNewPassRe").get()[0].classList.add("missing")
        valid = false;
      }
      if (!valid) {
        setTimeout(() => {
          $("#inputMyNewPass").get()[0].classList.remove("missing")
          $("#inputMyNewPassRe").get()[0].classList.remove("missing")
          $("#inputMyOldPass").get()[0].classList.remove("missing")
        }, 3000)
        return;
      }
      let response = await api.setUserPassword(this.formChangePass);
      if (response) {
        this.formChangePass.oldPassword = "";
        this.formChangePass.newPassword = "";
        this.formChangePass.newPasswordRe = "";
        console.log("Password changed.");
      }
    },
// SET MyProfile
    async setMyUserData() {
      let valid = true;
      //if (this.myProfileData.email != this.myProfileData.emailRe
      //|| this.myProfileData.email === ""
      //|| this.myProfileData.emailRe === "") {
      //  $("#inputMyUserEmail").get()[0].classList.add("missing")
      //  $("#inputMyUserEmailRe").get()[0].classList.add("missing")
      //  valid = false;
      //}
      if (this.myProfileData.name === "") {
        $("#inputMyUserName").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.myProfileData.lastName === "") {
        $("#inputMyUserLastName").get()[0].classList.add("missing")
        valid = false;
      }
      if (!valid) {
        setTimeout(() => {
          $("#inputMyUserName").get()[0].classList.remove("missing")
          $("#inputMyUserLastName").get()[0].classList.remove("missing")
          $("#inputMyUserEmail").get()[0].classList.remove("missing")
          $("#inputMyUserEmailRe").get()[0].classList.remove("missing")
        }, 3000)
        return;
      }
      let response = await api.setUserData(this.myProfileData);
      console.log(response);
      if (response["status-code"] === "200") {
        window.alert("El usuario ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar el usuario.");
      }
      this.getMyProfileData();
    },
    async setMyCompanyData() {
      let response = await api.setCompanyData(this.myCompanyData);
      this.$emit('getCurrentCompanyData', this.getMyCompanyData);
      console.log(response);
      if (response["status-code"] === "200") {
        window.alert("La empresa ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar la empresa.");
      }
    },
// SET My profile picture
    async setMyCompanyImage() {
      let response = await api.setCompanyImage(this.myCompanyImageForm);
      console.log(response);
      this.onRemoveMyNewCompanyImage();
      await this.getMyCompanyData();
    }
  },
  async created() {
    this.getMyProfileData();
    await this.getMyCompanyData();
  }
}
</script>

<style>
.myCompanyImagePreview {
  max-width: 100%;
  margin: auto; 
}
#cardMyProfile {
  padding-right: 1rem !important;
}
#cardMyCompany {
  padding-left: 1rem !important;
}
@media (max-width: 1199px) {
  #cardMyProfile {
    padding-right: 0.5rem !important;
  }
  #cardMyCompany {
    padding-left: 0.5rem !important;
  }
}
</style>