<template>
<!-- MODAL New Expedition -->
  <div id="newExpeditionModal"
  class="col-12 justify-content-center pe-6"
  style="padding: 15vh 20%">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">

<!-- HEAD New Expedition -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100">
        <div class="col-10">
          <h4 class="tx-left tx-clomos-white mb-2 ps-1 pt-2">
            <ion-icon name="arrow-redo-outline" class="tx-27 me-2 align-bottom"></ion-icon>
            {{dictionary['Nueva expedición'][session.user.language]}}
          </h4>
        </div>

        <div class="col-2 m-0 p-0" style="display: flex; justify-content: end;"
        @click="$emit('toggleNewExpeditionModal')">	
          <ion-icon name="close" class="ms-auto tx-46 tx-white align-bottom"></ion-icon>
        </div>
      </div>

      <div class="col-12 p-0 w-100">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="card-body">

<!-- CONTENT New Expedition -->
            <form class="form-material border-1 p-3" style="overflow: scroll; overflow-x: auto; overflow-y: hidden;">
              <div class="container-fluid">
                
                <div class="row">
<!-- ALBARAN ID-->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Identificador del albarán'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <input v-model="newExpeditionData.id" id="inputNewExpeditionId" type="text" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

<!-- ORDER ID-->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['Identificador del pedido'][session.user.language] }}
                    </label>
                    <div class="col-md-12">
                      <input v-model="newExpeditionData.orderId" id="inputNewExpeditionOrderId" type="text" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

                </div>
                  
<!-- ALBARAN SRC-->

                <div class="row pt-3 ps-2 pe-2">
                  <div id="recipeUploadFormContainer col-12" class="w-100 p-3 pt-0" style="display: flex; justify-content: space-around; gap: 1rem;">
                    <input type="file" id="recipeUploadSrc" class=" w-100 file-button btn btn-secondary rounded" style="height: 3rem; min-width: 180px;"
                    @change="onRecipeChanged" accept=".pdf">
                  </div>
                </div>
                
                <div class="row">

<!-- FECHA DE EJECUCIÓN -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Fecha de ejecución'][session.user.language]}}
                    </label>
                    <div class="col-12">
                      <input v-model="newExpeditionData.executionDateTime"
                      id="inputNewExpeditionExecutionDateTime" :type="(session.browser === 'firefox') ?'date' :'datetime-local'" class="form-control form-control-line col-12 m-0 p-1 pt-2" style="color: black;">
                    </div>
                  </div>

<!-- FECHA DE CREACIÓN -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Fecha de creación'][session.user.language]}}
                    </label>
                    <div class="col-12">
                      <input v-model="creationDateTimeInput" disabled
                      id="inputNewExpeditionExecutionDateTime" :type="(session.browser === 'firefox') ?'date' :'datetime-local'" class="form-control form-control-line col-12 m-0 p-1 pt-2" style="color: black;">
                    </div>
                  </div>

                </div>

                <div class="col-12 p-3">
                  <hr class="tx-clomos-dark m-0">
                </div>

                <div class="col-12 ps-2 pe-2">
                  <label class="col-12 tx-bold tx-clomos-dark text-nowrap ps-3 pe-3">
                    {{dictionary['Orden'][session.user.language]}}
                  </label>
                </div>

                <div class="row ms-2 me-2">
<!-- PRODUCTO -->
                  <div class="form-group col-lg-3 col-12 p-1">
                    <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['Referencia'][session.user.language] }}
                    </label>
                    <div class="col-12 p-0">
                      <input v-model="targetProduct.reference" id="inputSearchProductId" type="text" autocomplete="off"
                      list="productStocks" class="form-control form-control-line ps-0 pe-0" style="color:black;"
                      @input="(event)=>{onTargetProduct(event)}">
                    </div>
                    <datalist id="productStocks">
                      <option v-for="product in filteredProductList" :key="product" :value="product.variables.dynamic.referencia.value"> 
                        {{ product.label }}
                      </option>
                    </datalist>
                  </div>

                  <div class="form-group col-lg-1 col-12 p-1">
                    <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['Talla'][session.user.language] }}
                    </label>
                    <div class="col-md-12 p-0">
                      <input v-model="inputNewExpeditionSize" id="inputNewExpeditionSize" type="text" :disabled="isSizeDisabled"
                      class="form-control form-control-line ps-0 pe-0" style="color:black;">
                    </div>
                  </div>
                  
                  <div class="form-group col-lg-2 col-12 p-1">
                    <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['Color'][session.user.language] }}
                    </label>
                    <div class="col-md-12 p-0">
                      <input v-model="inputNewExpeditionColor" id="inputNewExpeditionColor" type="text" :disabled="isColorDisabled"
                      class="form-control form-control-line ps-0 pe-0" style="color:black;">
                    </div>
                  </div>

                  <div class="form-group col-lg-3 col-12 p-1">
                    <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['Caducidad'][session.user.language] }}
                    </label>
                    <div class="col-md-12 p-0">
                      <input v-model="inputNewExpeditionExpirationDate" id="inputNewExpeditionExpirationDate" type="date" :disabled="isExpirationDateDisabled"
                      class="form-control form-control-line ps-0 pe-0" style="color:black;">
                    </div>
                  </div>

                  <div class="form-group col-lg-2 col-12 p-1">
                    <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['Unidades'][session.user.language] }}
                    </label>
                    <div class="col-md-12 p-0">
                      <input v-model="inputNewExpeditionUnits" id="inputNewExpeditionUnits" type="number" 
                      class="form-control form-control-line ps-0 pe-0" style="color:black;">
                    </div>
                  </div>

                  <div class="form-group col-lg-1 col-12 p-0 pt-3 m-0">
                    <ion-icon name="add-circle-outline" class="tx-clomos-dark targets-icons m-0 p-0 pt-2 tx-40" style="vertical-align: middle;"
                    @click="(targetProduct.reference!='')?this.newExpeditionData.targetList.push({
                      'id': targetProduct.id, 
                      'reference':targetProduct.reference, 
                      'color':inputNewExpeditionColor, 
                      'size':inputNewExpeditionSize, 
                      'expirationDate':inputNewExpeditionExpirationDate, 
                      'units': inputNewExpeditionUnits
                    }):''">
                    </ion-icon>
                  </div>

                </div>

                <div class="row ms-2 me-2" v-for="(target,index) in newExpeditionData.targetList" :key="target">
<!-- TARGETS -->
                  <div class="form-group col-lg-3 col-12 p-1">
                    <div class="col-md-12 p-0">
                      <div class="border-bottom tx-13 pb-1" style="color:black;">
                      {{ target.reference }}
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-lg-1 col-12 p-1">
                    <div class="col-md-12 p-0">
                      <div class="border-bottom tx-13 pb-1" style="color:black;">
                      {{ (target.size != "") ?target.size :"-" }}
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-lg-2 col-12 p-1">
                    <div class="col-md-12 p-0">
                      <div class="border-bottom tx-13 pb-1" style="color:black;">
                      {{ (target.color != "") ?target.color :"-" }}
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-lg-3 col-12 p-1">
                    <div class="col-md-12 p-0">
                      <div class="border-bottom tx-13 pb-1" style="color:black;">
                      {{ (target.expirationDate != "") ?target.expirationDate :"-" }}
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-lg-2 col-12 p-1">
                    <div class="col-md-12 p-0">
                      <div class="border-bottom tx-13 pb-1" style="color:black;">
                      {{ target.units }}
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-md-1 col-1 p-0 m-0">
                    <ion-icon name="remove-circle-outline" class="tx-clomos-dark targets-icons m-0 p-0 tx-40" style="vertical-align: middle;"
                    @click="delete newExpeditionData.targetList.splice(index,1)"></ion-icon>
                  </div>

                </div>

                <div class="row">
<!-- SUBMIT -->

                  <div class="form-group col-lg-6 col-12 p-0 pt-3 m-0 ms-auto me-auto">
                    <div class="col-md-12">
                      <button type="button" id="btnSubmitCreateNewExpedition" class="btn btn-clomos w-100"
                      @click="setNewExpeditionData">
                        {{dictionary['Crear nueva expedición'][session.user.language]}}
                      </button>
                    </div>
                  </div>

                </div>

              </div>

            </form>
              
          </div>
        </div>
      </div>
      
    </div>
  </div>

</template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {
  name: "NewExpeditionModal",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    productId: {
      type: String,
      requiered: true,
    },
  },
  data() {
    return {
      newExpeditionData: {
        id: "",
        userId: this.session.user.id,
        type: "retirada",
        receiptFile: "",
        receiptSrc: "",
        executionDateTime: "",
        creationDateTime: new Date(),
        targetList: []
      },
      targetProduct: {
        id: "",
        reference: "",
      },
      availableProductList: [],
      inputNewExpeditionSize: "",
      inputNewExpeditionColor: "",
      inputNewExpeditionExpirationDate: "",
      inputNewExpeditionUnits: 0,
      isSizeDisabled : true,
      isColorDisabled : true,
      isExpirationDateDisabled : true,
    }
  },
  computed: {
    creationDateTimeInput() {
      let dt = new Date();
      dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset());
      return dt.toISOString().slice(0, 16);
    },
    filteredProductList() {
      let filteredProductList = [];
      for (let i in this.availableProductList)
        if (this.availableProductList[i].variables.dynamic.referencia.value.includes(this.targetProduct.reference)
        || this.targetProduct.reference === "")
          filteredProductList.push(this.availableProductList[i]);
      return filteredProductList;
    }
  },
  emits: ["toggleNewExpeditionModal"],
  methods: {
    // GET
    async getProductList() {
      let request = {
        nifCif: "showAll",
        limit: 0,
        offset: 0,  
        where: ""
      }
      let response = await api.getProductList(request);
      this.availableProductList = api.parseProductsFromResponse(response);
    },
    // SET
    async setNewExpeditionData() {
      let valid = true;
      if (this.newExpeditionData.targetList.length === 0) {
        $("#inputSearchProductId").get()[0].classList.add("missing")
        $("#inputNewExpeditionSize").get()[0].classList.add("missing")
        $("#inputNewExpeditionColor").get()[0].classList.add("missing")
        $("#inputNewExpeditionExpirationDate").get()[0].classList.add("missing")
        $("#inputNewExpeditionUnits").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.newExpeditionData.executionDateTime === "") {
        $("#inputNewExpeditionExecutionDateTime").get()[0].classList.add("missing")
        valid = false;
      }
      if (!valid) {
        setTimeout(() => {
          $("#inputSearchProductId").get()[0].classList.remove("missing")
          $("#inputNewExpeditionSize").get()[0].classList.remove("missing")
          $("#inputNewExpeditionColor").get()[0].classList.remove("missing")
          $("#inputNewExpeditionExpirationDate").get()[0].classList.remove("missing")
          $("#inputNewExpeditionUnits").get()[0].classList.remove("missing")
          $("#inputNewExpeditionExecutionDateTime").get()[0].classList.remove("missing")
        }, 3000)
        return;
      }
      let response = await api.postNewExpedition(this.newExpeditionData);
      console.log(response);
      if (response["status-code"] === "201") {
        this.$emit('toggleNewExpeditionModal');
        window.alert("La expedición ha sido creada satisfactoriamente.");
      }
      else {
        window.alert("Error al crear la expedición.");
      }
    },
    onRecipeChanged(event) {
      if (event.target.files[0] != undefined && event.target.files[0].size > 2097152) {
        alert("El tamaño del fichero debe ser inferior a 2Mb.");
        return;
      }
      this.newExpeditionData.receiptFile = event.target.files[0];
      this.newExpeditionData.receiptSrc = event.target.files[0].name;
    },
  // TARGET
    onTargetProduct(event) {
      this.inputNewExpeditionSize = "";
      this.inputNewExpeditionColor = "";
      this.inputNewExpeditionExpirationDate = "";
      let targetReference = event.target.value;
      for (let i in this.availableProductList) {
        if (this.availableProductList[i].variables.dynamic.referencia.value === targetReference) {
          this.targetProduct = {
            id: this.availableProductList[i].id,
            reference: this.targetProduct.reference,
          }
          this.isSizeDisabled = (this.availableProductList[i].variables.dynamic.talla === undefined);
          this.isColorDisabled = (this.availableProductList[i].variables.dynamic.color === undefined);
          this.isExpirationDateDisabled = (this.availableProductList[i].variables.dynamic.fechaCaducidad === undefined);
        }
      }
    }
  },
  async created() {
    await this.getProductList();
  }
}
</script>

<style>

#newExpeditionModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

.targets-icons:hover {
  color: var(--main-dark-50);
}

@media (max-width: 767px) {
  #stockImgUploadFormContainer {
    flex-direction: column;
  }
}
</style>