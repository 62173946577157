<template>
  <div class="simple-keyboard"></div>
</template>

<script>
import Keyboard from "simple-keyboard";

export default {
  name: "KeyboardComponent",
  props: {
    codeInput: {
      type: String
    }
  },
  data() {
    return {
      keyboard: null
    }
  },
  mounted() {
    this.keyboard = new Keyboard('simple-keyboard', {
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      layout: {
        'default': [
          '1 2 3 4 5 6 7 8 9 0 {bksp}',
          'q w e r t y u i o p',
          'a s d f g h j k l',
          'z x c v b n m {lock}',
          '{space}'
        ],
        'shift': [
          '1 2 3 4 5 6 7 8 9 0 {bksp}',
          'Q W E R T Y U I O P',
          'A S D F G H J K L',
          'Z X C V B N M {lock}',
          '{space}'
        ]
      }
    });
  },
  methods: {
    onChange(input) {
      this.$emit("onChange", input);
    },
    onKeyPress(button) {
      this.$emit("onKeyPress", button);
      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === "{shift}" || button === "{lock}") this.handleShift();
    },
    handleShift() {
      let currentLayout = this.keyboard.options.layoutName;
      let shiftToggle = currentLayout === "default" ? "shift" : "default";

      this.keyboard.setOptions({
        layoutName: shiftToggle
      });
    }
  },
  watch: {
    codeInput(input) {
      this.keyboard.setInput(input);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.hg-theme-default {background-color:#ddd; color: #111111; border-radius:5px;box-sizing:border-box;font-family:Arial;font-size:20px;overflow:hidden;padding:5px;touch-action:manipulation;-webkit-user-select:none;-moz-user-select:none;user-select:none;width:100%}
.hg-theme-default .hg-button span {pointer-events:none}
.hg-theme-default button .hg-button {border-width:0;font-size:inherit;outline:0}
.hg-theme-default .hg-button {display:inline-block;flex-grow:1}
.hg-theme-default .hg-row {display:flex}
.hg-theme-default .hg-row:not(:last-child) {margin-bottom:5px}
.hg-theme-default .hg-row .hg-button-container,
.hg-theme-default .hg-row .hg-button:not(:last-child) {margin-right:5px}
.hg-theme-default .hg-row>div:last-child {margin-right:0}
.hg-theme-default .hg-row .hg-button-container {display:flex}
.hg-theme-default .hg-button {-webkit-tap-highlight-color:rgba(0,0,0,0);align-items:center;background:#fff;border-bottom:1px solid #b5b5b5;border-radius:5px;box-shadow:0 0 3px -1px rgba(0,0,0,.3);box-sizing:border-box;cursor:pointer;display:flex;height:50px;justify-content:center;padding:5px}
.hg-theme-default .hg-button .hg-standardBtn {width:20px}
.hg-theme-default .hg-button
.hg-activeButton {background:#efefef}
.hg-theme-default
.hg-layout-numeric .hg-button {align-items:center;display:flex;height:60px;justify-content:center;width:33.3%}
.hg-theme-default .hg-button
.hg-button-numpadadd,
.hg-theme-default .hg-button
.hg-button-numpadenter {height:85px}
.hg-theme-default .hg-button
.hg-button-numpad0 {width:105px}
.hg-theme-default .hg-button
.hg-button-com {max-width:85px}
.hg-theme-default .hg-button
.hg-standardBtn
.hg-button-at {max-width:45px}
.hg-button-bksp {min-width:100px}
.hg-button-lock {min-width:100px}
.hg-theme-default .hg-button
.hg-selectedButton {background:rgba(5,25,70,.53);color:#fff}
.hg-theme-default .hg-button
.hg-standardBtn[data-skbtn=".com"] {max-width:82px}
.hg-theme-default .hg-button
.hg-standardBtn[data-skbtn="@"] {max-width:60px}
.hg-candidate-box {background:#ececec;border-bottom:2px solid #b5b5b5;border-radius:5px;display:inline-flex;margin-top:-10px;max-width:272px;position:absolute;transform:translateY(-100%);-webkit-user-select:none;-moz-user-select:none;user-select:none}ul
.hg-candidate-box-list {display:flex;flex:1;list-style:none;margin:0;padding:0}li
.hg-candidate-box-list-item {align-items:center;display:flex;height:40px;justify-content:center;width:40px}li
.hg-candidate-box-list-item:hover {background:rgba(0,0,0,.03);cursor:pointer}li
.hg-candidate-box-list-item:active {background:rgba(0,0,0,.1)}
.hg-candidate-box-prev:before {content:"◄"}
.hg-candidate-box-next:before {content:"►"}
.hg-candidate-box-next .hg-candidate-box-prev {align-items:center;color:#b5b5b5;cursor:pointer;display:flex;padding:0 10px}
.hg-candidate-box-next {border-bottom-right-radius:5px;border-top-right-radius:5px}
.hg-candidate-box-prev {border-bottom-left-radius:5px;border-top-left-radius:5px}
.hg-candidate-box-btn-active {color:#949494}
</style>
