<template>
<!-- SECTION Installation Status -->
  <div id="statusSection" class="col-12 justify-content-center pe-3"
  :class="(visibility.sideMenu)?'section-container-sm' :'section-container-lg'">
    <div class="row col-12 p-0 m-0 bg-caja section-container rounded-5">

  <!-- HEADER Installation Status -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100" >
        <div :class="(this.$route.params.currentDisplay==='status')?'col-lg-6 col-md-12 col-12 justify-content-center':'col-12 justify-content-center'" style="display: inherit">
          <!--<ion-icon v-if="session.user.bookmark==='devices'" name="bookmark" class="bookmark-button"></ion-icon>
          <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
          <h4 class="tx-left tx-clomos-white text-nowrap mb-2 ps-1 pt-2" style="height:40px;">
            <ion-icon name="pulse-outline" class="tx-27 me-2 align-bottom"></ion-icon>
            <span> {{dictionary["Estado"][session.user.language] }} </span>
            <span v-if="deviceData.label != undefined"> {{ ": "+deviceData.label }} </span>
            <span v-else> {{ ": " + dictionary['No hay dispositivos disponibles'][session.user.language] }} </span>
          </h4>
        </div>

<!-- Selector de Sistema -->
        <div v-if="this.$route.params.currentDisplay==='status'"
        class="col-lg-3 col-md-12 col-12 p-2 pb-1 justify-content-center">	
          <select
          :value="currentCompany.nifCif"
          class="form-control h-100 text-nowrap bg-white" 
          style="border: solid 2px white ; color: black;"
          @change="onCurrentCompanyChanged">
            <option v-if="availableCompanies[0]!=undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
            <option v-for="company in availableCompanies" :key="company.nifCif"
            :v-if="company.nifCif != session.user.nifCif
            && company.relation != undefined
            && company.relation.referenceCompany === this.session.user.nifCif"
            :value="company.nifCif">
              {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
            </option>
          </select>
        </div>

        <div v-if="this.$route.params.currentDisplay==='status'"
        class="col-lg-3 col-md-12 col-12 p-2 pb-1">	
          <select
          v-model="targetDeviceId"
          id="selectTargetDevice" data-live-search="true"
          @change="targetDevice(targetDeviceId)"
          class="col-md-6 form-control pt-0 pb-0 h-100 text-nowrap" 
          style="border: solid 1px white">
            <option v-for="device in deviceList" :key="device['id']" :value="device['id']" :selected="device['id'] === targetDeviceId">
              {{device['label']}}
            </option>
          </select>
        </div>
      </div>

      <div v-if="targetDeviceId != ''" class="m-0 p-0">

        <!-- CARD Variables -->
        <div class="col-12 p-3 pb-0 pe-0 d-flex flex-wrap">
          <div v-for="variableOptions in statusGaugeChartOptions" :key="variableOptions"
          id="cardVariables" class="col-lg-3 col-md-6 col-12 m-0 p-0 pe-3 pb-3">
            <h5 class="bg-clomos-midtone rounded-top-3 p-1 mb-0 tx-clomos-white">
              {{variableOptions.series[0].data[0].name}}
            </h5>

            <div class="border-1 p-0 m-0 rounded-bottom-3">
              <VueEcharts 
              :option="variableOptions" :id="variableOptions.series[0].data[0].name+'GaugeChartCanvas'"
              class="variableGaugeChart ps-0 pe-0" style="height: 15rem;">
              </VueEcharts>
              <span class="tx-14 tx-bold tx-clomos-dark"> {{ dictionary['Fecha'][session.user.language]+": "}} </span>
              <span class="tx-14"> {{ this.$dayjs(new Date(variableOptions.valueDateTime)).utc("z").local().tz(this.session.user.dateTimeZone).format(this.session.user.dateTimeFormat) }} </span>
            </div>

          </div>
        </div>
        
        <!-- CARD Last Day -->
        <div id="cardLastDay" class="col-lg-12 col-md-12 col-12 p-3 pt-0">
          <h5 class="bg-clomos-midtone rounded-top-3 p-1 mb-0 tx-clomos-white">
            {{ dictionary['Último día'][session.user.language] }}
          </h5>

          <div class="border-1 pt-3 pb-3 m-0"
          style="overflow: scroll; overflow-y: hidden;">
            <div class="m-0 p-0" style="min-width: 70rem; height: 30rem;">
              <VueEcharts :v-if="lastDayLineChartOptions[0]!=undefined"
              :option="lastDayLineChartOptions"
              style="height: 30rem;"
              id="lastDayChartCanvas" class="col-12 ps-0 pe-0">
              </VueEcharts>
            </div>
          </div>
        </div>

      </div>

      <div v-else class="p-3">
        <span class="tx-18 tx-bold tx-clomos-midtone"> 
          {{dictionary['No hay dispositivos disponibles'][session.user.language]}} 
        </span> 
      </div>
    
    </div>

  </div>

</template>

<script>
import { VueEcharts } from 'vue3-echarts';
import echartsOptionsJson from '@/assets/json/echarts_options.json';
// Clomos
import api from '@/services/api';

export default {
  name: "InstallationStatusComponent",
  components: { VueEcharts },
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    requestedDeviceId: {
      type: String,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    }
  },
  watch: { 
    currentCompany: async function() {
      if (this.$route.params.currentDisplay === 'status') {
        await this.getDeviceListByCompany();
        if (this.deviceList[0] != undefined) {
          this.targetDeviceId = this.deviceList[0].id;
          await this.getDeviceData();
        }
      }
      this.targetDevice(this.targetDeviceId)
      this.variableHistory = {};
      await this.getDeviceVariableHistory();
    },
    requestedDeviceId: async function(newId) {
      this.targetDevice(newId);
    }
  },
  data() {
    return {
      // LAST DAY CHART
      lineChartOptions: JSON.parse(JSON.stringify(echartsOptionsJson))["lineChartOptions"],
      // GAUGE CHARTS
      gaugeChartOptions: JSON.parse(JSON.stringify(echartsOptionsJson))["gaugeChartOptions"],
      deviceData : {
        id: "",
        nifCif: "",
        dataConsumed: "",
        label: "",
        dclId: "",
        dclType: "",
        dclClass: "",
        typeName: "",
        distributionGroup: "",
        parent: "",
        isBase: "",
        isPublic: "",
        token: "",
        imgSrc: "",
        emplacement: "",
        position: "",
        fechaUltimaComunicacion: "",
        tieneAlarmaActivada: "",
        version: "",
        variables: {
          analog: {},
          digital: {},
          dynamic: {}
        }
      },
      variableHistory: {},
      deviceList: [],
      targetDeviceId: this.$route.params.targetEntity,
      // COLORS
      colors: ["#001e76", "#e96526", "#0086ff", "#eda37f"]
    }
  },
  computed: {
    // DIGITAL GAUGE CHARTS
    statusGaugeChartOptions() {
      let statusGaugeChartOptions = {};
      let rawDeviceData = JSON.parse(JSON.stringify(this.deviceData));
      if (rawDeviceData.variables === undefined) return statusGaugeChartOptions;
      for (let i in rawDeviceData.variables.analog) {
        if (rawDeviceData.variables.analog[i].isConfiguration === 'false') {
          statusGaugeChartOptions[i] = JSON.parse(JSON.stringify(this.gaugeChartOptions));
          statusGaugeChartOptions[i].series[0].detail.formatter = "{value} " + rawDeviceData.variables.analog[i].unit;
          if(rawDeviceData.variables.analog[i].unit === "%"){
            statusGaugeChartOptions[i].series[0].max = 100;
            statusGaugeChartOptions[i].series[0].min = 0;
          }
          else{
            statusGaugeChartOptions[i].series[0].max = Number(rawDeviceData.variables.analog[i].max);
            statusGaugeChartOptions[i].series[0].min = Number(rawDeviceData.variables.analog[i].min);
          }
          statusGaugeChartOptions[i].series[0].data[0] = { 
            name: rawDeviceData.variables.analog[i].label.charAt(0).toUpperCase()+rawDeviceData.variables.analog[i].label.slice(1), 
            value: Number(rawDeviceData.variables.analog[i].value).toFixed(2)
          };
          statusGaugeChartOptions[i].valueDateTime = rawDeviceData.variables.analog[i].valueDateTime; 
        }
      }
      return statusGaugeChartOptions;
    },
    // LAST DAY CHART
    lastDayLineChartOptions() {
      let lastDayLineChartOptions;
      let now = new Date().getTime();
      let yesterday = new Date().setDate(new Date().getDate() - 1);
      
      lastDayLineChartOptions = JSON.parse(JSON.stringify(this.lineChartOptions));
      if (this.targetDeviceId === "") return lastDayLineChartOptions;
      lastDayLineChartOptions.axisPointer = {
        label: {
          formatter: (function(dataTarget) {
            let d = new Date(dataTarget.value);
            return String(d.getHours()).padStart(2,"0")+":"+String(d.getMinutes()).padStart(2,"0")+":"+String(d.getSeconds()).padStart(2,"0");
          })
        },
      }
      lastDayLineChartOptions.xAxis = {
        min: yesterday,
        max: now,
        axisLabel: {
          formatter: (function(value) {
            let d = new Date(value);
            return String(d.getHours()).padStart(2,"0")+":"+String(d.getMinutes()).padStart(2,"0")+":"+String(d.getSeconds()).padStart(2,"0");
          })
        }
      }
      lastDayLineChartOptions.yAxis = {
        type: "value"
      }
      let i = 0;
      for (let variable in this.variableHistory) {
        lastDayLineChartOptions.legend.data[i] = variable;
        lastDayLineChartOptions.series[i] = {
          data : this.variableHistory[variable],
          name : variable,
          type: "line",
          color : this.colors[i],
          smooth : true,
          step : false,
        }
        i+=1;
      }
      return lastDayLineChartOptions;
    }
  },
  emits: ["setCurrentCompany", "getAvailableCompanies"],
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({params: {currentCompanyNifCif: value}})
      this.$emit('setCurrentCompany',value);
    },
    // GET
    async getDeviceData(callback) {
      let response = await api.getDeviceData(this.targetDeviceId);
      this.deviceData = api.parseDeviceFromResponse(response);
      if (callback && typeof callback === "function") callback(this.deviceData);
    },
    async getDeviceListByCompany(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getDeviceListByCompany(request);
      this.deviceList = api.parseDevicesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.deviceList);
    },
    // TARGET
    async targetDevice(newId) {
      this.targetDeviceId = newId;
      await this.getDeviceData();
      this.$router.replace({params: {targetEntity: this.targetDeviceId}})
      this.variableHistory = {};
      await this.getDeviceVariableHistory();
    },
    async getDeviceVariableHistory(callback) {
      if (this.deviceData != {}) for (let variableType in this.deviceData.variables) {
        for (let variable in this.deviceData.variables[variableType]) {
          if (this.deviceData.variables[variableType][variable] == undefined || this.deviceData.variables[variableType][variable].saveHistory!="true") continue;
          let request = {
            id: this.deviceData.id,
            variableLabel: this.deviceData.variables[variableType][variable].label,
            variableType: this.deviceData.variables[variableType][variable].type,
            limit: 0,
            offset: 0,  
            endDateTime: api.parseDateTimeToString(new Date()),
            startDateTime: api.parseDateTimeToString(new Date().setDate(new Date().getDate() - 1)),
          };
          let response = await api.getDeviceVariableHistory(request);
          let rawHistory = api.parseVariableHistoryFromResponse(response);
          this.variableHistory[this.deviceData.variables[variableType][variable].label] = [];
          for (let i in rawHistory) {
            this.variableHistory[this.deviceData.variables[variableType][variable].label][i] = [
              // DATE
              this.$dayjs(new Date(rawHistory[i][0])).utc("z").local().tz(this.session.user.dateTimeZone).$d,
              // VALUE
              new Number(rawHistory[i][1]).toFixed(2)
            ];
          }
          if (callback && typeof callback === "function") callback(this.deviceList);
        }
      }
    }
  },
  async created() {
    if (this.$route.params.currentDisplay === 'status') {
      await this.getDeviceListByCompany();
      if (this.deviceList[0] != undefined && this.targetDeviceId === "") {
        this.targetDeviceId = this.deviceList[0].id;
        this.$router.replace({params: {targetEntity: this.targetDeviceId}})
        await this.getDeviceData();
      }
      else this.targetDevice(this.targetDeviceId)
      this.variableHistory = {};
      await this.getDeviceVariableHistory();
    }
  }
}
</script>

<style>
  #cardRelaysStatusContainer {
    display: flex;
    gap: 1rem;
  }
  #cardDigitalInputsContainer {
    display: flex;
    gap: 1rem;
  }
@media (max-width: 767px) {
  #cardOverall, #cardCurrentStatus, #cardSCC {
    padding-right: 1rem !important;
  }
  #cardRelaysStatusContainer, #cardDigitalInputsContainer {
    display: block;
  }
}
</style>