<template>
<!-- MODAL Edit Emplacement -->
  <div id="EditEmplacementModal"
  class="col-12 justify-content-center pe-6"
  style="padding: 15vh 20%">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">

<!-- HEAD Edit Emplacement -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100">
        <div class="col-10">
          <h4 class="tx-left tx-clomos-white mb-2 ps-1 pt-2">
            <ion-icon name="location-outline" class="tx-27 me-2 align-bottom"></ion-icon>
            {{dictionary['Editar emplazamiento'][session.user.language]+": "+editEmplacementData.label}}
          </h4>
        </div>

        <div class="col-2 m-0 p-0" style="display: flex; justify-content: end;"
        @click="$emit('toggleEditEmplacementModal')">	
          <ion-icon name="close" class="ms-auto tx-46 tx-white align-bottom"></ion-icon>
        </div>
      </div>

      <div class="col-12 p-0 w-100">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="card-body">

<!-- CONTENT Edit Emplacement -->
            <form class="form-material border-1 p-3 pt-0 overflow-hidden">
              <div class="container-fluid p-3">
                
                <div class="row">
                  
<!-- NOMBRE -->
                  <div class="form-group col-lg-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Nombre'][session.user.language]}} *
                    </label>
                    <div class="col-md-12">
                      <input v-model="editEmplacementData.label" id="inputEditEmplacementName" type="text" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

<!-- LOCALIZACION -->
                  <div class="form-group col-lg-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Localización'][session.user.language]}} *
                    </label>
                    <div class="col-md-12">
                      <textarea v-model="editEmplacementData.location" id="inputEditEmplacementLocation" type="text" 
                      class="form-control form-control-line" style="color:black; height: 2rem;"/>
                    </div>
                  </div>

                </div>
              
                <div class="row">
                  
<!-- COORDENADAS -->
                  <div class="form-group col-lg-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Geoposición'][session.user.language]}} *
                    </label>
                    <!-- CONTENT Map -->
                    <GoogleMap
                    api-key="AIzaSyD1IQjTF3ZPy1I-xfKIytnl0eQ28VuFw-o" 
                    :center="editEmplacementLatLngPosition" :zoom="5"
                    @click="onSetNewCoordinates" 
                    id="emplacementMapContent" class="m-0" style="width: 100%; height: 250px; padding: 0  1.25rem">
                      <Marker :options="{position: editEmplacementLatLngPosition}" />
                    </GoogleMap>
                  </div>

                  <div class="d-flex flex-column col-lg-6 col-12 p-0">

  <!-- LATITUD -->
                    <div class="form-group col-12 p-1">
                      <label class="col-12 tx-bold tx-clomos-midtone">
                        {{dictionary['Latitud'][session.user.language]}} *
                      </label>
                      <div class="col-md-12">
                        <input v-model="editEmplacementData.geoJSON.geometry.coordinates[0]" id="inputEditEmplacementLat" type="text" 
                        class="form-control form-control-line" style="color:black;">
                      </div>
                    </div>

  <!-- LONGITUD -->
                    <div class="form-group col-12 p-1">
                      <label class="col-12 tx-bold tx-clomos-midtone">
                        {{dictionary['Longitud'][session.user.language]}} *
                      </label>
                      <div class="col-md-12">
                        <input v-model="editEmplacementData.geoJSON.geometry.coordinates[1]" id="inputEditEmplacementLng" type="text" 
                        class="form-control form-control-line" style="color:black;">
                      </div>
                    </div>

<!-- SUBMIT -->
                    <div class="col-12 mt-auto">
                      <button type="button" id="btnSubmitCreateEditEmplacement" class="btn btn-clomos w-100"
                      @click="setEditEmplacementData">
                        {{dictionary['Guardar cambios'][session.user.language]}}
                      </button>
                    </div>

                  </div>

                </div>

              </div>

            </form>
              
<!-- FORM Emplacement Image -->
            <div class="col-12 pt-3 p-0 overflow-hidden">
              <div class="justify-content-center bg-clomos-midtone rounded-5">
                
                <label class=" col-12 p-3 pb-0 tx-white tx-bold text-nowrap">
                  {{dictionary["Imagen del emplazamiento"][session.user.language].toUpperCase()}}
                </label>
                <label class=" col-12 p-3 pt-0 tx-white tx-light text-nowrap">
                  {{dictionary["Tamaño recomendado"][session.user.language]+': 1000x1000px'}}
                </label>
                
                <img id="emplacementImage" class="w-100" style="max-width: 750px; width: auto; padding: 0 1rem 1rem 1rem;" :src="displayEmplacementImage(this.editEmplacementData.imgSrc)">

                <div v-if="emplacementImageForm.file!=''" class="myEmplacementImagePreview w-100 p-3 m-auto">
                  <div class="d-flex flex-column">
                    <button type="button" title="Remove file" @click="onRemoveEditEmplacementImage()" style="line-height: 0;" class="p-1 m-0 rounded ms-auto">
                      <ion-icon name="close" class="tx-18 tx-black align-bottom"></ion-icon>
                    </button>
                    <img style="max-width: 750px; width: auto; padding-top: 1rem; margin: auto;" :src="displayEditEmplacementThumbnail(emplacementImageForm.file)" />
                  </div>
                </div>

                <div id="emplacementImageUploadFormContainer" class="w-100 p-3 pt-0" style="display: flex; justify-content: space-around; gap: 1rem;">
                  
                  <input type="file" id="emplacementImageUploadSrc" class=" w-100 file-button btn btn-secondary rounded" style="height: 3rem; min-width: 180px;"
                  @change="onChangeEditEmplacementImage" accept=".png, .jpg">

                  <button type="button" id="emplacementImageUploadSubmit" class=" w-100 file-button btn btn-dark rounded" style="height: 3rem; min-width: 180px;"
                  @click="setEditEmplacementImage">
                    <ion-icon name="log-in-outline" class="me-3"></ion-icon>
                    {{dictionary["Guardar archivo"][session.user.language]}}
                  </button>

                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
      
    </div>
  </div>

</template>

<script>
import { GoogleMap, Marker } from "vue3-google-map";
// Clomos
import api from '@/services/api';

export default {
  name: "EditEmplacementModal",
  components: { 
    GoogleMap, Marker
  },
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    emplacementId: {
      type: String,
      requiered: true,
    },
  },
  data() {
    return {
      editEmplacementData: {
        nifCif: this.currentCompany.nifCif,
        id: "",
        dclId: "",
        dclTypeId: "",
        label: "",
        location: "",
        geoJSON: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [0,0]
          }
        },
        imgSrc: "",
      },
      emplacementImageForm: {
        id: this.emplacementId,
        nifCif: "",
        src: "",
        file: ""
      }
    }
  },
  computed: {
    editEmplacementLatLngPosition() {
      let position = {
        lat: Number(this.editEmplacementData.geoJSON.geometry.coordinates[0]), 
        lng: Number(this.editEmplacementData.geoJSON.geometry.coordinates[1])
      }
      return position;
    }
  },
  emits: ["toggleEditEmplacementModal"],
  methods: {
    // DISPLAY
    displayEmplacementImage(src) {
      if (src!=undefined && src!='') {
        try {
          return 'https://apilockers.clomos.es/ficheros/ficheros.php?fichero='+src+'&identificadorusuario='+this.session.user.id+'&Token='+localStorage.getItem("LockersAuthToken");
        }
        catch(e) { 
          console.warn(e);
        }
      }
      return require('/src/assets/img/placeholder_image.jpg');
    },
    displayEditEmplacementThumbnail(file) {
      if (file === '' || file === undefined) return "";
      let fileSrc = URL.createObjectURL(file);
        setTimeout(() => {
            URL.revokeObjectURL(fileSrc);
        }, 1000);
      return fileSrc;
    },
    onChangeEditEmplacementImage(event) {
      if (event.target.files[0] != undefined && event.target.files[0].size > 2097152) {
        alert("El tamaño del fichero debe ser inferior a 2Mb.");
        return;
      }
      this.emplacementImageForm.nifCif = this.editEmplacementData.nifCif;
      this.emplacementImageForm.file = event.target.files[0];
      this.emplacementImageForm.src = event.target.files[0].name;
    },
    onRemoveEditEmplacementImage() {
      this.emplacementImageForm.nifCif = "";
      this.emplacementImageForm.file = "";
      this.emplacementImageForm.src = "";
    },
    // GET 
    async getEditEmplacementData(callback) {
      let response = await api.getEmplacementData(this.emplacementId);
      this.editEmplacementData = api.parseEmplacementFromResponse(response);
      if (callback && typeof callback === "function") callback(this.editEmplacementData);
    },
    // SET
    async setEditEmplacementData() {
      this.newCoordinates = {};
      let response = await api.setEmplacementData(this.editEmplacementData);
      console.log(response);
      if (response["status-code"] === "200") {
        window.alert("El emplazamiento ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar el emplazamiento.");
      }
      this.getEditEmplacementData();
    },
    onSetNewCoordinates(event) {
      this.editEmplacementData.geoJSON = {
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [Number(event.latLng.lat()), Number(event.latLng.lng())]
        }
      }
    },
    async setEditEmplacementImage() {
      let response = await api.setEmplacementImage(this.emplacementImageForm);
      console.log(response);
      this.onRemoveEditEmplacementImage();
      this.getEditEmplacementData();
    }
  },
  created() {
    this.getEditEmplacementData();
  }
}
</script>

<style>

#EditEmplacementModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

@media (max-width: 767px) {
  #emplacementImageUploadFormContainer {
    flex-direction: column;
  }
}
</style>