<template>
<!-- SIDE MENU Full -->
  <div :class="(visibility.sideMenu)?'nav-side-menu':'nav-side-menu-collapsed'" class="bg-clomos overflow-hidden"> 
    <div style="{position: relative; height: 70px; margin: 1rem 0 0 0;}"></div>
    <div class="w-100"
    v-for="page in availablePages" :key="page">
      <a class="menu-item-text tx-white mb-3 text-nowrap"
      :class="(this.$route.params.currentDisplay===page.value)?'tx-focus':'tx-light'"
      @click="onSideMenuItemClicked(page)">
        <ion-icon :name="page.icon" :alt="dictionary[page.text][session.user.language]"
        class="menu-item-icon tx-22 align-bottom ms-3"></ion-icon>
        <span v-if="visibility.sideMenu" class="ms-3">
          {{dictionary[page.text][session.user.language]}}
        </span>
      </a> 
      <hr style="{padding-right: 10%; padding-left: 10%; width: 100%;}">
    </div>
    <!-- LOG OUT -->
    <div class="w-100">
      <a class="menu-item-text tx-white tx-light mb-3 text-nowrap"
      @click="logOutRequest">
        <ion-icon name="exit-outline" :alt="dictionary['Cerrar sesión'][session.user.language]"
        class="menu-item-icon tx-22 align-bottom ms-3"></ion-icon>
        <span v-if="visibility.sideMenu" class="ms-3"> 
          {{dictionary["Cerrar sesión"][session.user.language]}} 
        </span>
      </a> 
      <hr style="{padding-right: 10%; padding-left: 10%; width: 100%;}">
    </div>
  </div>
</template>

<script>
export default {
  name: "SideMenuComponent",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    availableModules: {
      type: Object,
      requiered: true,
    }
  },
  data() {
    return {
      pages: [
        {text:"Dashboard", icon: "home-outline", value:"dashboard"},
        {text:"Emplazamientos", icon: "location-outline", value:"emplacements"},
        {text:"Empresas", icon: "briefcase-outline", value:"companies"},
        {text:"Máquinas", icon: "cog-outline", value:"machines"},
        {text:"Dispositivos", icon: "hardware-chip-outline", value:"devices"},
        {text:"Estado", icon: "pulse-outline", value:"status"},
        {text:"Productos", icon: "cube-outline", value:"products"},
        {text:"Stock", icon: "layers-outline", value:"stocks"},
        {text:"Recepción", icon: "arrow-undo-outline", value:"receptions"},
        {text:"Expedición", icon: "arrow-redo-outline", value:"expeditions"},
        {text:"Pedidos", icon: "basket-outline", value:"orders"},
        {text:"Gráficas", icon: "stats-chart-outline", value:"charts"},
        {text:"Avisos", icon: "mail-outline", value:"warnings"},
        {text:"Usuarios", icon: "people-outline", value:"users"},
      ],
    }
  },
  computed: {
    availablePages() {
      let availablePages = [];
      let i = 0
      for (let page in this.pages) {
        for (let modul in this.availableModules) {
          if (this.pages[page].value === this.availableModules[modul]) {
            availablePages[i] = this.pages[page];
            i+=1;
          }
        }
      }
      return availablePages;
    }
  },
  emits: ["toggleSideMenu", "logOutRequest" ],
  methods: {
    // NAVIGATE 
    onSideMenuItemClicked(page) {
      if(this.visibility.sideMenu && window.innerWidth <= 767)
        this.$emit('toggleSideMenu')
      this.$router.replace({params: {currentDisplay: page.value, targetEntity: ""} })
    },
    // LOG OUT
    logOutRequest() {
      this.$emit('logOutRequest');
    }
  },
}
</script>

<style>
.nav-side-menu {
  height: 100vh;
  width: 17.25rem;
  color: #FFF;
  font-weight:400;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  transition: all 0.3s;
}

@media (max-width: 767px) {
  .nav-side-menu {
    height: 100vh;
    width: 100%;
    transition: all 0.3s;
  }
}

.menu-item-icon {
  cursor: pointer;
}

.menu-item-icon:hover {
  opacity: 0.75;
  transition: all 0.3s;
}

.nav-side-menu-collapsed {
  height: 100%;
  width: 70px;
  color: #FFF;
  font-weight:400;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  transition: all 0.3s;
}

.menu-item-text{
  float: left;
  margin-left: 10%;
  transition: all 0.3s;
}
</style>
