<template>
<!-- SECTION Receptions-->
  <div id="receptionsSection" class="col-12 justify-content-center pe-3"
  :class="(visibility.sideMenu)?'section-container-sm' :'section-container-lg'">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">
  
    <!-- HEADER Receptions -->

      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100" >

        <div class="col-lg-6 col-md-12 col-12 justify-content-center" style="display: inherit">
          <!--<ion-icon v-if="session.user.bookmark==='receptions'" name="bookmark" class="bookmark-button"></ion-icon>
          <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
          <h4 class="tx-left tx-clomos-white text-nowrap mb-2 ps-1 pt-2" style="height:40px;">

            <ion-icon name="arrow-undo-outline" class="tx-27 me-2 align-bottom"></ion-icon>
            {{dictionary["Recepción de productos"][session.user.language]}}
          </h4>
        </div>

<!-- HEADER Display Type -->
        <div class="d-flex justify-content-between col-lg-3 col-md-6 col-12 p-0 ps-1 pe-1 m-0">	
          <div class="w-third p-1 pt-2">	
            <button @click="displayType='all'"
            :alt='dictionary["Todos"][session.user.language]'
            :style="(displayType==='all')?'border-color: coral' :'border-color: white'"
            class="col-xl-4 col-md-4 col-sm-12 header-tab-button btn bg-clomos rounded p-0 text-nowrap">
              <ion-icon name="ellipsis-vertical-circle-outline" 
              :style="(displayType==='all')?'color: coral':'color: white'"
              class="tx-23" style="vertical-align: middle; margin-top: -0.25rem;"></ion-icon>
            </button>
          </div>
          <div class="w-third p-1 pt-2">	
            <button @click="displayType='toDo'"
            :alt='dictionary["Pendiente"][session.user.language]'
            :style="(displayType==='toDo')?'border-color: coral' :'border-color: white'"
            class="col-xl-4 col-md-4 col-sm-12 header-tab-button btn bg-clomos rounded p-0 text-nowrap">
              <ion-icon name="time-outline" 
              :style="(displayType==='toDo')?'color: coral':'color: white'"
              class="tx-23" style="vertical-align: middle; margin-top: -0.25rem;"></ion-icon>
            </button>
          </div>
          <div class="w-third p-1 pt-2">	
            <button @click="displayType='done'"
            :alt='dictionary["Emitido"][session.user.language]'
            :style="(displayType==='done')?'border-color: coral' :'border-color: white'"
            class="col-xl-4 col-md-4 col-sm-12 header-tab-button btn bg-clomos rounded p-0 text-nowrap">
              <ion-icon name="checkmark-circle-outline" 
              :style="(displayType==='done')?'color: coral':'color: white'"
              class="tx-23" style="vertical-align: middle; margin-top: -0.25rem;"></ion-icon>
            </button>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-12 p-2 pb-1">	
          <button id="btnDisplayNewReceptionModal"
          class="btn btn-clomos bg-white rounded p-1 inner-border fw-normal text-nowrap"
          style="width: 100%; height:100%; vertical-align: middle; border: solid 2px white"
          @click="toggleNewReceptionModal">
              <ion-icon name="add-circle-outline" class="tx-clomos-dark me-2 tx-20" style="vertical-align: middle; margin-top: -0.25rem;"></ion-icon>
              <span class="ms-auto tx-clomos-dark">{{dictionary["Añadir recepción"][session.user.language]}}</span>
          </button>
        </div>
      </div>

      <div class="m-0 p-3 overflow-auto">
        <table id="receptionsDataTable" class="display w-100 border-1 p-0 m-0 rounded-3">
          <thead class="bg-clomos-light">
            <tr>
              <th v-for="column in receptionsColumns" :key="column.data"
              :width="column.width" class="tx-black fw-medium text-nowrap">
                {{dictionary[column.text][session.user.language]}}
              </th>
            </tr>
          </thead>
        </table>
      </div>

    </div>
  </div>

<!-- MODAL New Reception -->
  <NewReceptionModal
  v-if="display.newReceptionModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :availableCompanies="availableCompanies"
  v-on:toggleNewReceptionModal="toggleNewReceptionModal">
  </NewReceptionModal> 

<!-- MODAL Prepare Reception -->
  <PrepareReceptionModal
  v-if="display.prepareReceptionModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :availableCompanies="availableCompanies"
  :targetReceptionId="targetReceptionId"
  v-on:togglePrepareReceptionModal="togglePrepareReceptionModal">
  </PrepareReceptionModal> 

<!-- MODAL Delete Text Alert -->
  <TextAlertModal
  v-if="display.endReceptionModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :alertText="dictionary['Recepcionar'][session.user.language] + ': ' + targetReceptionId"
  v-on:acceptAlert="onDeleteReception"
  v-on:dismissAlert="toggleEndReceptionModal">
  </TextAlertModal>

<!-- MODAL Delete Text Alert -->
  <TextAlertModal
  v-if="display.deleteReceptionModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :alertText="dictionary['Recepción a eliminar'][session.user.language] + ': ' + targetReceptionId"
  v-on:acceptAlert="onDeleteReception"
  v-on:dismissAlert="toggleDeleteReceptionModal">
  </TextAlertModal>

</template>

<script>
import $ from 'jquery';
import NewReceptionModal from '@/components/receptions/NewReceptionModal';
import PrepareReceptionModal from '@/components/receptions/PrepareReceptionModal';
import TextAlertModal from '@/components/other/TextAlertModal';
// Clomos
import api from '@/services/api';

export default {
  name: "ReceptionsComponent",
  components: { PrepareReceptionModal, NewReceptionModal, TextAlertModal  },
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    dataTableOptions: {
      type: Object,
      requiered: true,
    }
  },
  watch: { 
    currentCompany: async function() {
      await this.getReceptionList();
      this.displayReceptionsDataTable();
    }
  },
  data() {
    return {
      receptionsColumns: [
        {text:"Albarán", data: "receptionId", width: "10%",
          render: (data) => {
            return "<ion-icon name='list-circle-outline' data-id="+data+" class='btnDetailReception btn tx-24 tx-primary  p-0 m-0'></ion-icon>";
          }
        },
        {text:"Identificador", data: "receptionId", width: "20%"},
        {text:"Empresa", data: "receptionCompany", width: "20%",
          render: (data) => {
            let companyName = data;
            if (this.availableCompanies.length > 0) {
              for (let company in this.availableCompanies) 
                if (this.availableCompanies[company].nifCif === data ) 
                  companyName = this.availableCompanies[company].name;
            } 
            return companyName;
          }
        },
        {text:"Fecha de ejecución", data: "receptionExecutionDateTime", width: "15%"},
        {text:"Fecha de creación", data: "receptionCreationDateTime", width: "15%"},
        {text:"Descargar", data: "receptionFiles", width: "5%",
          render: (data) => {
            return "<ion-icon name='download-outline' data-id="+data+" class='btnDownloadReception btn tx-24 tx-primary  p-0 m-0'></ion-icon>";
          }
        },
        {text:"Preparar", data: "receptionStatus", width: "5%",
          render: (data) => {
            return (data.status==="0")
              ? "<ion-icon name='chevron-forward-circle-outline' data-id="+data.id+" class='btnPrepareReception btn tx-24 tx-primary p-0 m-0'></ion-icon>"
              : "<ion-icon name='checkmark-circle-outline' data-id="+data.id+" class='btnPreparedReception btn tx-24 tx-success p-0 m-0' disabled></ion-icon>";
          }
        },
        {text:"Recepcionar", data: "receptionStatus", width: "5%",
          render: (data) => {
            return (data.status==="0")
              ? "<ion-icon name='chevron-forward-circle-outline' data-id="+data.id+" class='btn tx-24 tx-clomos-midtone opacity-50 p-0 m-0' disabled></ion-icon>"
              : (data.status==="1")
                ? "<ion-icon name='chevron-forward-circle-outline' data-id="+data.id+" class='btnEndReception btn tx-24 tx-primary p-0 m-0'></ion-icon>"
                : "<ion-icon name='checkmark-circle-outline' data-id="+data.id+" class='btnExecutedReception btn tx-24 tx-success p-0 m-0' disabled></ion-icon>";
          }
        },
        {text:"Eliminar", data: "receptionStatus", width: "5%",
          render: (data) => {
            return (data.status==="0")
              ? "<ion-icon name='trash-outline' data-id="+data.id+" class='btnDeleteReception btn tx-24 tx-primary p-0 m-0'></ion-icon>"
              : "<ion-icon name='trash-outline' data-id="+data.id+" class='btn tx-24 tx-clomos-midtone opacity-50 p-0 m-0' disabled></ion-icon>";
          }
        }
      ],
      displayType: "all",
      targetReceptionId: "",
      receptionList: [],
      receptionExecution: {
        volume: {
          height: 0,
          width: 0,
          depth: 0
        }
      },
      display: {
        newReceptionModal: false,
        prepareReceptionModal: false,
        endReceptionModal: false,
        deleteReceptionModal: false
      }
    }
  },
  emits: ["setCurrentCompany", "getAvailableCompanies"],
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({params: {currentCompanyNifCif: value}})
      this.$emit('setCurrentCompany',value);
    },
    displayReceptionsDataTable() {
      $("#receptionsDataTable").DataTable().clear()
      for (let i in this.receptionList) {
        $("#receptionsDataTable").DataTable().rows.add([{
          receptionId: this.receptionList[i].id, 
          receptionCompany: this.receptionList[i].company, 
          receptionType: this.receptionList[i].type,
          receptionStatus: {id: this.receptionList[i].status, status: this.receptionList[i].status}, 
          receptionFiles: this.receptionList[i].files, 
          receptionExecutionDateTime: this.receptionList[i].executionDateTime, 
          receptionCreationDateTime: this.receptionList[i].creationDateTime, 
        }]);
      }
      $("#receptionsDataTable").DataTable().draw();
      this.setButtonFunctions();
     if($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
    },
    async toggleNewReceptionModal() {
      if (this.display.newReceptionModal) {
        await this.getReceptionList();
        this.displayReceptionsDataTable();
      }
      this.display.newReceptionModal = !this.display.newReceptionModal;
    },
    async togglePrepareReceptionModal() {
      if (this.display.prepareReceptionModal) {
        await this.getReceptionList();
        this.displayReceptionsDataTable();
      }
      this.display.prepareReceptionModal = !this.display.prepareReceptionModal;
    },
    async toggleEndReceptionModal() {
      if (this.display.endReceptionModal) {
        await this.getReceptionList();
        this.displayReceptionsDataTable();
      }
      this.display.endReceptionModal = !this.display.endReceptionModal;
    },
    async toggleDeleteReceptionModal() {
      if (this.display.deleteReceptionModal) {
        await this.getReceptionList();
        this.displayReceptionsDataTable();
      }
      this.display.deleteReceptionModal = !this.display.deleteReceptionModal;
    },
    // BUTTONS
    setButtonFunctions() {
      $(".btnTargetReception").get().forEach(btn => {
        btn.addEventListener('click', this.onTargetReception);
      });
      $(".btnDetailReception").get().forEach(btn => {
        btn.addEventListener('click', this.onDetailReception)
      });
      $(".btnPrepareReception").get().forEach(btn => {
        btn.addEventListener('click', this.onPrepareReception);
      });
      $(".btnEndReception").get().forEach(btn => {
        btn.addEventListener('click', this.onEndReception);
      });
      $(".btnDeleteReception").get().forEach(btn => {
        btn.addEventListener('click', this.onDeleteReception);
      });
    },
    // GET
    async getReceptionList(callback) {
      let request = {
        userId: this.session.user.id
      };
      let response = await api.getReceptionList(request);
      console.log(response)
      //this.receptionList = api.parseReceptionsFromResponse(response);
      this.receptionList = [
        {
          id: "100",
          company: "DSI",
          type: "pedido",
          status: "0",
          files: "1A",
          executionDateTime: "3/8/2023",
          creationDateTime: "1/8/2023",
        },
        {
          id: "020",
          company: "DSI",
          type: "producto",
          status: "2",
          files: "2B",
          executionDateTime: "3/8/2023",
          creationDateTime: "1/8/2023",
        },
        {
          id: "003",
          company: "DSI",
          type: "producto",
          status: "1",
          files: "3C",
          executionDateTime: "3/8/2023",
          creationDateTime: "1/8/2023",
        },
      ]
      if (callback && typeof callback === "function") callback(this.receptionList);
    },
    // TARGET 
    onTargetReception(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetReceptionId = event.target.getAttribute('data-id')
    },
    // PREPARE
    onPrepareReception(event) {
      console.log('PREPARE WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetReceptionId = event.target.getAttribute('data-id')
      this.togglePrepareReceptionModal();
    },
    // DETAILS
    onDetailReception(event) {
      console.log('DETAIL WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetReceptionId = event.target.getAttribute('data-id')
      var tr = $(event.target).closest('tr');
      var row = $("#receptionsDataTable").DataTable().row(tr);
      if (row.child.isShown()) {
        event.target.name = "list-circle-outline";
        row.child.hide();
        tr.removeClass('shown');
      } 
      else {
        event.target.name = "chevron-up-circle-outline";
        let content = '' +
          '<table id="detailTable'+this.targetReceptionId+'" cellspacing="0" border="0" style="width:100%; margin: 0;">' +
            '<tr>' +
              '<td class="tx-bold">Producto</td>' +
              '<td class="tx-bold">Cantidad</td>' +
              '<td class="tx-bold">Talla</td>' +
              '<td class="tx-bold">Color</td>' +
              '<td class="tx-bold">Caducidad</td>' +
              '<td class="tx-bold">Zona</td>' +
            '</tr>' +
            '<tr>' +
              '<td>' + this.targetReceptionId + '-AL' + '</td>' +
              '<td>' + '102' + '</td>' +
              '<td>' + 'M' + '</td>' +
              '<td>' + 'Rojo' + '</td>' +
              '<td>' + '-' + '</td>' +
              '<td>' + 'Ambiente' + '</td>' +
            '</tr>' +
            '<tr>' +
              '<td>' + this.targetReceptionId + '-AL' + '</td>' +
              '<td>' + '41' + '</td>' +
              '<td>' + 'XL' + '</td>' +
              '<td>' + 'Verde' + '</td>' +
              '<td>' + '-' + '</td>' +
              '<td>' + 'Ambiente' + '</td>' +
            '</tr>' +
            '<tr>' +
              '<td>' + this.targetReceptionId + '-AF' + '</td>' +
              '<td>' + '41' + '</td>' +
              '<td>' + 'XL' + '</td>' +
              '<td>' + 'Verde' + '</td>' +
              '<td>' + '-' + '</td>' +
              '<td>' + 'Ambiente' + '</td>' +
          '</tr>' +
          '</table>';
        row.child(content).show();
        document.getElementById('detailTable'+this.targetReceptionId).parentNode.style.padding = 0;
        tr.addClass('shown');
      }
    },
    // SET
    async onEndReception(event) {
      if (!this.display.endReceptionModal) {
        console.log('END WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetReceptionId = event.target.getAttribute('data-id');
      }
      else {
        // let response = await api.deleteOrder(this.targetReceptionId);
        // console.log(response);
      }
      this.toggleEndReceptionModal();
    },
    // DELETE
    async onDeleteReception(event) {
      if (!this.display.deleteReceptionModal) {
        console.log('DELETE WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetReceptionId = event.target.getAttribute('data-id');
      }
      else {
        let response = await api.deleteOrder(this.targetReceptionId);
        console.log(response);
      }
      this.toggleDeleteReceptionModal();
    }
  },
  async created() {
    await this.getReceptionList()
    this.$emit('getAvailableCompanies',(this.displayReceptionsDataTable));
  },
  mounted() {
    $("#receptionsDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn ms-auto' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.receptionsColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    );
  }
}
</script>

<style>
#prepareReceptionModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

.btnDetailReception:hover, .btnPrepareReception:hover, .btnDownloadReception:hover, .btnEndReception:hover, .btnDeleteReception:hover {
  color: var(--main-dark);
}
.btnPreparedReception:hover, .btnExecutedReception:hover {
  color: var(--main-dark-50);
}
</style>