<template>
  <div id="LockerApp">

    <div class="locker-app-container">
      <div v-if="displaySreenSaver" class="screen-saver">
      </div>

      <div id="accessTypeForm" class="box-shadow">
        <button class="box-shadow" :class="(accessMode==='client')?'mode-button-active':'mode-button'"
        @click="accessMode='client'">
          CLIENTE
        </button>
        <button class="box-shadow" :class="(accessMode==='delivery')?'mode-button-active':'mode-button'"
        @click="accessMode='delivery'">
          REPARTIDOR
        </button>
        <button class="box-shadow" :class="(accessMode==='return')?'mode-button-active':'mode-button'"
        @click="accessMode='return'">
          DEVOLUCIÓN
        </button>
        <button class="box-shadow" :class="(accessMode==='service')?'mode-button-active':'mode-button'"
        @click="accessMode='service'">
          SERVICIO
        </button>
      </div>

      <div id="codeForm" class="box-shadow">
        <div class="formInputRow">
          <input
            :value="codeInput"
            class="codeInput box-shadow"
            @input="onInputChange"
            placeholder="Introduce el código de recogida">
          <button id="searchCodeButton" class="box-shadow">
            {{(accessMode==='client')?'RECOGER'
            :(accessMode==='delivery')?'ENTREGAR'
            :(accessMode==='return')?'DEVOLVER'
            :'ACCEDER'}}
          </button>
        </div>

        <Keyboard @onChange="onChange" @onKeyPress="onKeyPress" :codeInput="codeInput"
        id="keyboardComponent"/>

      </div>
    </div>
  </div>
</template>

<script>
import Keyboard from '@/components/other/KeyboardComponent.vue'

export default {
  name: 'LockerApp',
  components: {
    Keyboard
  },
  data() {
    return {
      accessMode: "client",
      displaySreenSaver: false,
      screenSaverTimeOut: false,
      codeInput: "",
    }
  },
  methods: {
    onChange(input) {
      this.codeInput = input;
    },
    onKeyPress(button) {
      console.log("button", button);
    },
    onInputChange(input) {
      this.codeInput = input.target.value;
    },
  },
  created() {
    this.screenSaverTimeOut = setTimeout(() => {
      this.displaySreenSaver = true;
    }, 60000)
  }
}
</script>

<style>
#LockerApp {
  font-family: Arial;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background: rgb(36,0,9);
  background: linear-gradient(130deg, #babad4 0%, #8594c8 35%, #001e76 100%);
  padding: auto;
  margin: auto;
  max-height: 600px;
  min-height: 600px;
  max-width: 800px;
  min-width: 800px;
}

#accessTypeForm {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: fit-content;
  padding: 1rem 2rem;
  gap: 1rem;
  margin: 0 0 1rem auto;
  color: #333333;
  background-color: #aaa;
  border: solid #ddd 5px; 
  border-radius: 10px; 
}

#codeForm {
  padding: 1rem;
  color: #111111;
  background-color: #aaa;
  border: solid #ddd 5px; 
  border-radius: 10px; 
}

#searchCodeButton {
  width: 150px;
  height: 50px;
  font-family: Arial;
  font-size: 17px;
  font-weight: 600;
  background-color: white;
  color: #333333;
  border: solid #ddd 5px; 
  border-radius: 5px;
}

.locker-app-container {
  padding: 35px 60px;
}

.formInputRow {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  gap: 1rem;
  overflow: hidden;
}

.mode-button {
  width: 135px;
  height: 50px;
  font-family: Arial;
  font-size: 17px;
  font-weight: 600;
  background-color: white;
  color: #333333;
  border: solid #ddd 5px; 
  border-radius: 5px;
  transition: all 0.3s;
}

.mode-button-active {
  width: 155px;
  height: 50px;
  font-family: Arial;
  font-size: 17px;
  font-weight: 600;
  background-color: white;
  color: #ff9000;
  border: solid #ff900080 5px; 
  border-radius: 5px;
  transition: all 0.3s;
}

.codeInput {
  width: 100%;
  height: 50px;
  margin: auto;
  text-align: center;
  font-size: 23px;
  margin-bottom: 1rem;
  border: solid #ddd 5px; 
  border-radius: 5px;
  box-sizing: border-box;
}
.codeInput:active, .codeInput:focus {
  outline: none;
}

.box-shadow {
  box-shadow: 3px 3px 5px #11111133;
}

.row {
  display: flex;
  flex-direction: row;
}

.logo {
  height: auto;
  width: auto;
  margin: 0 2rem 2rem 0;
}
</style>
