<template>
<!-- SECTION Inbox-->
  <div id="inboxSection" class="col-12 justify-content-center pe-3"
  :class="(visibility.sideMenu)?'section-container-sm' :'section-container-lg'">
  
    <!-- HEADER Inbox -->
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">
      <div class="row colcol-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100" >
        
        <div class="col-lg-8 col-md-12 col-12">
          <!--<ion-icon v-if="session.user.bookmark==='warnings'" name="bookmark" class="bookmark-button"></ion-icon>
          <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
          
          <h4 class="tx-left tx-clomos-white mb-2 ps-1 pt-2">
            <ion-icon name="mail-outline" class="tx-27 me-2 align-bottom"></ion-icon>
            {{dictionary["Bandeja de entrada"][session.user.language]}}
          </h4>
        </div>
      </div>

      <!-- CONTENT Inbox -->
      <div class="m-0 me-3 p-3 pe-0 overflow-auto">
        <table  id="inboxDataTable" class="display w-100 border-1 p-0 m-0 rounded-3">
          <thead class="bg-clomos-light">
            <tr>
              <th v-for="column in inboxColumns" :key="column.data"
              :width="column.width" class="tx-black fw-medium text-nowrap">
                {{dictionary[column.text][session.user.language]}}
              </th>
            </tr>
          </thead>
        </table>
      </div>

    </div>

  </div>

<!-- SECTION Message-->
  <div id="messageSection" class="col-12 justify-content-center pe-3"
  :class="(visibility.sideMenu)?'section-container-sm' :'section-container-lg'">
  
    <!-- HEADER Message -->
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">
      <div class="row colcol-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100" >
        <div class="col-lg-8 col-md-12 col-12">
          <h4 class="tx-left tx-clomos-white mb-2 ps-1 pt-2">
            <ion-icon name="mail-open-outline" class="tx-27 me-2 align-bottom"></ion-icon>
            {{dictionary["Mensaje"][session.user.language]}}
          </h4>
        </div>
      </div>

      <!-- CONTENT Message -->
      <div v-if="targetWarningId!=''"
      id="messageContent" class="m-0 p-2 ps-3 pe-3 overflow-auto">

        <div class="row m-0 p-0">
          <p id="warningEmitterDateTime" class="col-8 m-0 p-0" style="text-align: right;">
            {{warningList[targetWarningId].warningDateTime}}
          </p>
        </div>

        <div class="row m-0 p-0">
          <label for="warningTypeDisplay" class="col-12 m-0 p-0 fw-bold">
            {{dictionary['Tipo'][session.user.language]}}:
          </label>

          <p id="warningTypeDisplay" class="col-4 m-0 p-0" style="text-align: left;">
            {{warningList[targetWarningId].warningType}}
          </p>
        </div>

        <div class="row m-0 p-0">
          <label for="warningTypeDisplay" class="col-12 m-0 p-0 fw-bold">
            {{dictionary['Mensaje'][session.user.language]}}:
          </label>

          <p id="warningDescriptionDisplay" class="col-12 m-0 p-0" style="text-align: left;">
            {{warningList[targetWarningId].warningDescription}}
          </p>
        </div>

      </div>
      
      <div v-else id="messageContent" class="m-0 p-2 ps-3 pe-3 overflow-auto">
        <span class="tx-18 tx-bold tx-clomos-midtone"> 
          {{dictionary['No hay avisos disponibles'][session.user.language]}} 
        </span> 
      </div>

    </div>

  </div>

  
  <!-- MODAL Delete Text Alert -->
  <TextAlertModal
  v-if="display.deleteWarningModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :alertText="dictionary['Aviso a eliminar'][session.user.language] + ': ' + targetWarningId"
  v-on:acceptAlert="onDeleteWarning"
  v-on:dismissAlert="toggleDeleteWarningModal">
  </TextAlertModal>

</template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {
  name: "NewCompanyModal",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    dataTableOptions: {
      type: Object,
      requiered: true,
    }
  },
  watch: { 
    currentCompany: async function() {
      await this.getWarningList();
      this.displayWarningsDataTable();
    }
  },
  data() {
    return {
      inboxColumns: [
        {text:"Leído", data: "isTreated", width: "5%",
          render: (data) => {
            return (data.value)
              ?"<ion-icon name='mail-open-outline' data-index="+data.index+" class='btnTargetWarning btn tx-24 tx-clomos-midtone'></ion-icon>"
              :"<ion-icon name='mail-outline' data-index="+data.index+" class='btnTargetWarning btn tx-24 tx-clomos-midtone'></ion-icon>"
          }
        },
        {text:"Tipo", data: "warningType", width: "10%",
          render: (data) => {
            return (data) ?"Alarma" :"Notificación"
          }
        },
        {text:"Mensaje", data: "warningDescription", width: "40%",
          render: (data) => {
            return (data.length > 32)
              ?data.substring(0,32)+"..."
              :data
          }
        },
        {text:"Fecha aviso", data: "warningDateTime", width: "10%",
          render: (data) => {
            if (data === "") return data
            else return this.$dayjs(new Date(data)).utc("z").local().tz(this.session.user.dateTimeZone).format(this.session.user.dateTimeFormat+" HH:mm:ss")
          }
        },
        {text:"Fecha lectura", data: "readingDateTime", width: "10%",
          render: (data) => {
            if (data === "") return data
            else return this.$dayjs(new Date(data)).utc("z").local().tz(this.session.user.dateTimeZone).format(this.session.user.dateTimeFormat+" HH:mm:ss")
          }
        },
        {text:"Fecha silenciado", data: "muteDateTime", width: "10%",
          render: (data) => {
            if (data === "") return data
            else return this.$dayjs(new Date(data)).utc("z").local().tz(this.session.user.dateTimeZone).format(this.session.user.dateTimeFormat+" HH:mm:ss")
          }
        },
        {text:"Estado", data: "isMuted", width: "10%",
          render: (data) => {
            if (data.value)
              return "<ion-icon name='power-outline' data-id="+data.id+" class='btnSwitchWarning btn tx-24 tx-success'></ion-icon>"
            else 
              return "<ion-icon name='power-outline' data-id="+data.id+" class='btnSwitchWarning btn tx-24 tx-clomos-midtone'></ion-icon>"
          }
        },
        {text:"Eliminar", data: "warningId", width: "5%",
          render: (data) => {
            return "<ion-icon name='trash-outline' data-id="+data+" class='btnDeleteWarning btn tx-24 tx-warning'></ion-icon>"
          }
        }
      ],
      targetWarningId: this.$route.params.targetEntity,
      warningList: [],
      display: {
        deleteWarningModal: false
      }
    }
  },
  methods: {
    // DISPLAY
    displayWarningsDataTable() {
      $("#inboxDataTable").DataTable().clear()
      for (let i in this.warningList) {
        $("#inboxDataTable").DataTable().rows.add([{
          warningId: this.warningList[i].warningId,
          isTreated: {index: i, id: this.warningList[i].warningId, value: Boolean(this.warningList[i].warningTreated)},
          isRead: {index: i, id: this.warningList[i].warningId, value: Boolean(this.warningList[i].readingDateTime != '')},
          isMuted: {index: i, id: this.warningList[i].warningId, value: Boolean(this.warningList[i].muteDateTime != '')},
          warningType: this.warningList[i].warningIsAlarm,
          warningDescription: this.warningList[i].warningDescription,
          warningDateTime: this.warningList[i].warningDateTime, 
          readingDateTime: this.warningList[i].readingDateTime, 
          muteDateTime: this.warningList[i].muteDateTime,
        }]);
      }
     $("#inboxDataTable").DataTable().draw();
      this.setButtonFunctions();
     if($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
    },
    async toggleDeleteWarningModal() {
      if (this.display.deleteWarningModal) {
        await this.getWarningList();
        this.displayWarningsDataTable();
      }
      this.display.deleteWarningModal = !this.display.deleteWarningModal;
    },
    // BUTTONS 
    setButtonFunctions() {
      $(".btnTargetWarning").get().forEach(btn => {
        btn.addEventListener('click', this.onTargetWarning);
      });
      $(".btnEditWarning").get().forEach(btn => {
        btn.addEventListener('click', this.onEditWarning);
      });
      $(".btnSwitchWarning").get().forEach(btn => {
        btn.addEventListener('click', this.onDeleteWarning);
      });
    },
    // GET
    async getWarningList(callback) {
      let response = await api.getCompanyWarnings(this.currentCompany.nifCif);
      this.warningList = api.parseWarningsFromResponse(response);
      if (callback && typeof callback === "function") callback(this.warningList);
    },
    // TARGET
    onTargetWarning(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-index'));
      this.targetWarningId = event.target.getAttribute('data-index');
      this.$router.replace({params: {targetEntity: this.targetWarningId}})
    },
    // DELETE
    async onDeleteWarning(event) {
      if (!this.display.deleteWarningModal) {
        console.log('DELETE WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetWarningId = event.target.getAttribute('data-id');
        this.$router.replace({params: {targetEntity: this.targetWarningId}})
      }
      else {
        let response = await api.deleteWarnings([this.targetWarningId]);
        console.log(response);
      }
      this.toggleDeleteWarningModal();
    }
  },
  async created() {
    await this.getWarningList();
    this.displayWarningsDataTable();
  },
  mounted() {
    $("#inboxDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn ms-auto' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.inboxColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    );
  }
}
</script>

<style>
.btnTargetWarning:hover, .btnSwitchWarning:hover {
  color: var(--main-dark);
}
</style>